import * as React from "react";
import styled from "@emotion/styled";
import { ITemplateGroup } from "../../models/templateGroup/templateGroup";
import { Translations } from "../../models/common/translations";
import UseCaseTemplateListItem from "./UseCaseTemplateListItem";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { templateActions } from "../../store/template";
import { useEffect, useState } from "react";
import { AppMenuIconButton, IAppMenuIconButtonMenuItem } from "../common/button/AppMenuIconButton";
import { AppMoreIcon } from "../common/icon/AppMoreIcon";

//Styles
const Stack = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;

const TemplateGroup = styled.div(
    {
        width: "100%",
    }
);

const Templates = styled.div(
    {
        width: "100%",
        display: "flex",
        alignItems: "stretch",
        flexFlow: "row wrap",
        cursor: "pointer",
    }
);

//Component
export interface IUseCaseTemplateGroupsListProps {
    useCaseId: string;
    templateGroups: ITemplateGroup[];
}

interface ISelectedGroupsState {
    menuItemId: string;
    groups: ITemplateGroup[];
};

const AllMenuItemId = "all";

export const UseCaseTemplateGroupsList = (props: IUseCaseTemplateGroupsListProps) => {
    const dispatch = useAppDispatch();
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const showArchived = useSelector((state: RootState) => state.template.showArchived);
    const [menuItems, setMenuItems] = useState<IAppMenuIconButtonMenuItem[]>([]);
    const [selectedGroupsState, setSelectedGroupsState] = useState<ISelectedGroupsState>({ menuItemId: AllMenuItemId, groups: [] });

    const handleShowArchived = () => {
        dispatch(templateActions.setShowArchived(true));
    };

    const handleHideArchived = () => {
        dispatch(templateActions.setShowArchived(false));
    };
    
    const changeGroup = (groups: ITemplateGroup[], menuItemId: string) => {
        setSelectedGroupsState({ menuItemId, groups });
        updateMenuItems(menuItemId);
        if (groups?.length && groups[0].templates?.length) {
            dispatch(templateActions.setTemplate(groups[0].templates[0]));
        }
    };

    const updateMenuItems = (selectedMenuItemId: string) => {
        setMenuItems([
            { id: "showArchived", title: Translations.ShowHidden, isCheckable: true, isChecked: showArchived, onClick: handleShowArchived },
            { id: "hideArchived", title: Translations.HideHidden, isCheckable: true, isChecked: !showArchived, onClick: handleHideArchived },
            { id: "divider_1", divider: true },
            {
                id: "templateGroup",
                title: Translations.TemplateGroup,
                subMenuItems: [
                    {
                        id: AllMenuItemId,
                        title: Translations.All,
                        isCheckable: true,
                        isChecked: selectedMenuItemId === AllMenuItemId,
                        onClick: () => changeGroup(props.templateGroups, AllMenuItemId)
                    },
                    ...props.templateGroups.map(i => {
                        return {
                            id: i.code,
                            title: i.name,
                            isCheckable: true,
                            isChecked: selectedMenuItemId === i.code,
                            onClick: () => changeGroup([i], i.code)
                        };
                    })
                ]
            },
        ]);
    };

    useEffect(() => {
        setSelectedGroupsState({ menuItemId: AllMenuItemId, groups: props.templateGroups });
        updateMenuItems(AllMenuItemId);
    }, [props.templateGroups]);

    return (
        <React.Fragment>
            {managementMode && !props.useCaseId && menuItems.length > 0
                ? <Stack>
                    <AppMenuIconButton title={Translations.More} icon={<AppMoreIcon />} menuItems={menuItems} />
                </Stack>
                : null}
            <div style={{ overflowY: "auto", overflowX: "hidden" }}>
                {selectedGroupsState.groups.map((templateGroup) =>
                    <TemplateGroup key={templateGroup.code}>
                        <h4>{templateGroup.name}</h4>
                        <Templates>
                            {templateGroup.templates.map((template) =>
                                <UseCaseTemplateListItem
                                    key={template.id}
                                    useCaseId={props.useCaseId}
                                    template={template}
                                />
                            )}
                        </Templates>
                    </TemplateGroup>
                )}
            </div>
        </React.Fragment>
    );
};

export default UseCaseTemplateGroupsList;