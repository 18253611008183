import { Checkbox, CheckboxProps } from "@fluentui/react-components";
import * as React from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";

//Component
export interface IFormBoolenFieldProps {
  label: string;
  name: string;
  control: Control<any>;
  rules?: UseControllerProps["rules"];
  defaultValue?: any;
}

export const FormBoolenField = (props: IFormBoolenFieldProps & CheckboxProps) => {
    return (
        <Controller name={props.name} control={props.control} rules={props.rules} defaultValue={!!props.defaultValue} render={({ field }) => (
            <Checkbox
                {...props}
                {...field}
                id={field.name}
                label={props.label}
                title={props.label}
                checked={field.value}
                onChange={field.onChange}
            />
        )}
        />
    );
};

export default FormBoolenField;