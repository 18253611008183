import * as React from "react";
import styled from "@emotion/styled";
import { ArrowUpload20Regular, FluentIconsProps } from "@fluentui/react-icons";

//Styles
const StyledDiv = styled.div<IFileIconButtonProps>`
    cursor: ${(props) => !props.disabled && "pointer"};
    border-radius: var(--borderRadiusMedium);
    padding: 5px;
    &:hover {
        color: var(--colorNeutralForeground2BrandHover);
        background-color: rgb(243, 242, 241);

    }
`;

//Component
export interface IFileIconButtonProps {
    accept?: string;
    disabled?: boolean;
    name?: string;
    onFileChange?: (file: File) => void;
}

export const FileIconButton = (props: IFileIconButtonProps & FluentIconsProps) => {
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.disabled || !e.target.files || e.target.files.length < 1) return;
        const file = e.target.files[0];
        props.onFileChange(file);
    };

    return (
        <label>
            <StyledDiv title={props.title}>
                <ArrowUpload20Regular />
                {!props.disabled &&
                    <input type="file" name={props.name} className="file" style={{ display: "none" }} multiple={false} onChange={handleFileChange} accept={props.accept} />
                }
            </StyledDiv>
        </label>
    );
};

export default FileIconButton;