import * as React from "react";
import { Translations } from "../../../models/common/translations";
import { ButtonProps } from "@fluentui/react-components";
import { AppIconButton } from "./AppIconButton";
import { AppCopyIcon } from "../icon/AppCopyIcon";

export const CopyIconButton = (props: ButtonProps) => (
    <AppIconButton
        {...props}
        icon={<AppCopyIcon />}
        title={Translations.Copy}
        aria-label={Translations.Copy} />
);
