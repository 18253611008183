import * as React from "react";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from "@fluentui/react-components";
import styled from "@emotion/styled";
//Styles
const StyledPopover = styled(MenuPopover)`
    max-height: 90vh;
    overflow-y: auto;
`;

//Component
export interface AppMenuItem {
    key: string;
    text: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

export interface AppMenuButtonProps {
    menuProps: AppMenuItem[];
    text?: string;
}

export const AppMenuButton = (props: AppMenuButtonProps) => (
    <Menu>
        <MenuTrigger disableButtonEnhancement>
            <MenuButton appearance="transparent">{props?.text}</MenuButton>
        </MenuTrigger>
        <StyledPopover>
            <MenuList>
                {props.menuProps?.map((item) =>
                    <MenuItem key={item.key} onClick={item.onClick}>{item.text}</MenuItem>
                )}
            </MenuList>
        </StyledPopover>
    </Menu>
);

export default AppMenuButton;