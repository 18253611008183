import { Base } from "../../framework/base";
import { ApiError } from "../common/apiError";
import { ApplicationSettings } from "../common/applicationSettings";

let frontServiceRootUrl: string = null;

export const updateServiceRootUrl = (serviceUrl: string) => {
    frontServiceRootUrl = serviceUrl;
    if (!frontServiceRootUrl || frontServiceRootUrl[frontServiceRootUrl.length - 1] !== "/") {
        frontServiceRootUrl = (frontServiceRootUrl ?? "") + "/";
    }
};

export const getRootUrl = (): string => {
    if (!frontServiceRootUrl) {
        updateServiceRootUrl(ApplicationSettings.readSettings()?.serviceUrl);
    }
    return frontServiceRootUrl ?? "";
};

const getErrorMessageFromBody = (text: string): string => {
    if (Base.isJsonString(text)) {
        const obj = JSON.parse(text);
        const message = obj.message ? obj.message : (obj.Message ? obj.Message : text);
        return message;
    } else {
        return text;
    }
};

export const handleResponse = (response: Response): any => {
    console.log(response);
    return new Promise<void>((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message
            const error = new ApiError();
            error.status = response.status;
            response.text().then(text => {
                error.message = getErrorMessageFromBody(text);
                reject(error);
            });
        }
    });
};

export function handleFileBlobResponse(response: Response): Promise<Blob> {
    console.log(response);
    return new Promise((resolve, reject) => {
        if (response.ok) {
            return resolve(response.blob());
        } else {
            // return error message
            const error = new ApiError();
            error.status = response.status;
            response.text().then(text => {
                error.message = getErrorMessageFromBody(text);
                reject(error);
            });
            return null;
        }
    });
}

export const handleError = (error): any => {
    console.log(error);
    const isString = Base.isString(error);
    const isAborted = !isString && error && error.name === "AbortError";
    return Promise.reject(isAborted ? null : (isString ? error : error && error.message));
};

const getJsonHeaders = (body: string | null = null): HeadersInit => {
    return {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=utf-8",
        "Content-Length": (body ? body.length : 0).toString(10),
    };
};

const getJsonRequestInit = (getJsonHeaders: (body: string) => HeadersInit, method: string = "GET", body: string = null): RequestInit => {
    return body
        ? {
            headers: getJsonHeaders(body),
            body,
            method
        }
        : {
            headers: getJsonHeaders(null),
            method
        };
};

export const getPublicApiCallUnTyped = (url: string, getJsonHeadersPar: (body: string) => HeadersInit = null): Promise<any> => {
    const getJsonHeadersCall = getJsonHeadersPar ?? getJsonHeaders;
    return fetch(getRootUrl() + url, getJsonRequestInit(getJsonHeadersCall))
        .then(handleResponse, handleError)
        .catch(handleError);
};

const getResult = <T>(data: any, type: (new (...args: any[]) => T)): T => {
    // ReSharper disable InconsistentNaming
    /* eslint-disable new-cap */
    return type
        ? !data ? new type() : new type(data)
        : !data ? <T><unknown>0 : <T>(data.result);
    /* eslint-enable new-cap */
    // ReSharper restore InconsistentNaming
};

export const getPublicApiCall = <T>(url: string, type: (new (...args: any[]) => T), getJsonHeaders: (body: string) => HeadersInit = null): Promise<T> => {
    return getPublicApiCallUnTyped(url, getJsonHeaders)
        .then(data => {
            return getResult(data, type);
        })
        .catch(handleError);
};

export const postPublicApiCallUnTyped = (url: string, body: string = null, getJsonHeadersPar: (body: string) => HeadersInit = null): Promise<any> => {
    const getJsonHeadersCall = getJsonHeadersPar ?? getJsonHeaders;
    return fetch(getRootUrl() + url, getJsonRequestInit(getJsonHeadersCall, "POST", body))
        .then(handleResponse, handleError)
        .catch(handleError);
};

export const postPublicApiCall = <T>(url: string, type: (new (...args: any[]) => T), body: string = null, getJsonHeadersPar: (body: string) => HeadersInit = null): Promise<T> => {
    return postPublicApiCallUnTyped(url, body, getJsonHeadersPar)
        .then(data => {
            return getResult(data, type);
        })
        .catch(handleError);
};

export const getErrorMessage = (error: any): string => {
    if (error instanceof ApiError || error instanceof Error) {
        return error.message;
    } else if (typeof error === "string") {
        return error;
    } else {
        return "Unkown error";
    }
};
