import { ApiResponse } from "../common/apiResponse";
import { ApiSaveSuccess } from "../common/apiSaveSuccess";
import { DocumentFormat, LanguageCode, TemplateThumbnailSize } from "../common/enums";
import { DocumentCreateResultBase64 } from "../document/documentCreateResultBase64";
import { DocumentCreateWithPreviewResult } from "../document/documentCreateWithPreviewResult";
import { TemplateEdit } from "../template/templateEdit";
import { TemplateThumbnailResult } from "../template/templateThumbnailResult";
import { TemplateGroups } from "../templateGroup/templateGroups";
import { postApiCall, postFileBlobApiCall } from "./tokenService";

export const getTemplateGroups = (languageCode: LanguageCode, templateGroup: string): Promise<TemplateGroups> => {
    return postApiCall("template/groups", TemplateGroups, JSON.stringify({
        languageCode,
        templateGroupCodes: templateGroup.split(",").map(i => i.trim()).filter(i => !!i)
    }));
};

export const getThumbnail = (templateCode: string, size: TemplateThumbnailSize): Promise<TemplateThumbnailResult> => {
    return postApiCall("template/thumbnail", TemplateThumbnailResult, JSON.stringify({
        templateCode,
        size
    }));
};

export const getDocumentWithPreview = (context: string, templateCode: string, languageCode: LanguageCode, format: DocumentFormat, documentData: string): Promise<DocumentCreateWithPreviewResult> => {
    return postApiCall("document/createwithpreview", DocumentCreateWithPreviewResult, JSON.stringify({
        context,
        templateCode,
        languageCode,
        format,
        documentData
    }));
};

export const getDocument = (context: string, templateCode: string, languageCode: LanguageCode, format: DocumentFormat, documentData: string): Promise<DocumentCreateResultBase64> => {
    return postApiCall("document/create", DocumentCreateResultBase64, JSON.stringify({
        context,
        templateCode,
        languageCode,
        format,
        documentData
    }));
};

export const getTemplateEdit = (templateCode: string): Promise<TemplateEdit> => {
    return postApiCall("template/edit", TemplateEdit, JSON.stringify({ code: templateCode }));
};

export const getTemplateFile = (templateFileId: string): Promise<Blob> => {
    return postFileBlobApiCall("template/file", JSON.stringify({ id: templateFileId }));
};

export const uploadTemplateFileContent = (data: any): Promise<ApiSaveSuccess> => {
    return postApiCall("template/fileupload", ApiSaveSuccess, JSON.stringify(data));
};

export const setTemplateState = (templateId: string, templateStateCode: string): Promise<ApiResponse> => {
    return postApiCall("template/setstate", ApiResponse, JSON.stringify({ id: templateId, state: templateStateCode }));
};

export const copyTemplate = (templateId: string, code: string, name: string): Promise<ApiSaveSuccess> => {
    return postApiCall("template/copy", ApiSaveSuccess, JSON.stringify({ id: templateId, code, name }));
};

export const removeTemplate = (templateId: string): Promise<ApiSaveSuccess> => {
    return postApiCall("template/remove", ApiSaveSuccess, JSON.stringify({ id: templateId }));
};
