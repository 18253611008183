import styled from "@emotion/styled";
import { SignOutRegular } from "@fluentui/react-icons";
import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import { Translations } from "../../models/common/translations";
import { useNavigate } from "react-router-dom";
import StateText from "./StateText";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { authActions } from "../../store/auth";
import { HomeIconButton } from "./button/HomeIconButton";
import { Button } from "@fluentui/react-components";
import { AppMenuIconButton, IAppMenuIconButtonMenuItem } from "./button/AppMenuIconButton";
import { AppMoreIcon } from "./icon/AppMoreIcon";

//Style
const TopBarDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleBarDiv = styled.div(
    {
        "marginTop": 10,
        "display": "flex",

        "& > h2": {
            flexShrink: "2",
            overflow: "hidden",
            marginRight: 10,
            marginBottom: 0,
            marginTop: 0
        },

        "& > div.tc > h2, & > div.tc > h3": {
        },

        "button:first-of-type,.buttons:first-of-type": {
            marginLeft: "auto"
        }
    }
);

const TitleBarSubTitle = styled.h3(
    {
        marginTop: 0,
        marginBottom: 0,
    }
);

//Component
export interface ITitleBarProps {
    children?: ReactNode;
    title: string;
    subTitle?: string;
    menuItems?: IAppMenuIconButtonMenuItem[];
}

export const TitleBar = (props: ITitleBarProps) => {
    const dispatch = useAppDispatch();
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = useState<IAppMenuIconButtonMenuItem[]>([]);

    useEffect(() => {
        const items: IAppMenuIconButtonMenuItem[] = props.menuItems ? props.menuItems.slice(0) : [];
        if (items.length) {
            items.push({ id: "divider", divider: true });
        }
        items.push(
            {
                id: "logout",
                title: Translations.Logout,
                icon: <SignOutRegular />,
                onClick: () => {
                    dispatch(authActions.logout());
                    return true;
                }
            }
        );
        setMenuItems(items);
    }, [props.menuItems, props.menuItems ? props.menuItems.map(i => i.id + (i.isChecked ?? false).toString()).join(",") : ""]);

    const returnHome = () => {
        navigate("/");
    };

    return (
        <React.Fragment>
            <TopBarDiv>
                <HomeIconButton onClick={() => returnHome()} />
                {managementMode &&
                    <StateText
                        text={Translations.ManagementMode}
                        color={"#FBC01040"}
                    />
                }
                <div style={{ marginRight: "10px" }}>
                    {(menuItems.length > 0
                        ? <AppMenuIconButton title={Translations.More} icon={<AppMoreIcon />} menuItems={menuItems} />
                        : <Button appearance="subtle" {...props} icon={<AppMoreIcon />} title={Translations.More} aria-label={Translations.More} />
                    )}
                </div>
            </TopBarDiv>
            <TitleBarDiv>
                <h2 title={props.title}>{props.title}</h2>
                {props.children}
            </TitleBarDiv>
            {!!props.subTitle &&
                <TitleBarSubTitle title={props.subTitle}>{props.subTitle}</TitleBarSubTitle>
            }
        </React.Fragment>
    );
};

export default TitleBar;