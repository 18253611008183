import * as React from "react";
import { ButtonProps, Menu, MenuButton, MenuDivider, MenuItem, MenuItemCheckbox, MenuList, MenuPopover, MenuPopoverProps, MenuTrigger, Slot } from "@fluentui/react-components";

export interface IAppMenuIconButtonMenuItem {
    id: string;
    title?: string;
    divider?: boolean;
    icon?: Slot<"span">;
    isCheckable?: boolean,
    isChecked?: boolean,
    subMenuItems?: IAppMenuIconButtonMenuItem[];
    onClick?: () => void;
}

interface IAppMenuIconButtonProps {
    title: string;
    icon: Slot<"span">;
    menuItems?: IAppMenuIconButtonMenuItem[];
}

const AppMenuPopover = (props: { menuItems: IAppMenuIconButtonMenuItem[] } & MenuPopoverProps) => {
    if (!props.menuItems || props.menuItems.length === 0) return null;

    return (
        <MenuPopover style={{ maxWidth: "173px", maxHeight: "100vh" }}>
            <MenuList>
                {props.menuItems.map((menuItem) => {
                    if (menuItem.divider) {
                        return <MenuDivider key={menuItem.id} />;
                    }
                    if (menuItem.isCheckable) {
                        return <MenuItemCheckbox key={menuItem.id} name="checkable" value={menuItem.id} icon={menuItem.icon} onClick={menuItem.onClick} persistOnClick={false}>{menuItem.title}</MenuItemCheckbox>;
                    }
                    if (menuItem.subMenuItems) {
                        return <AppMenuSubMenu key={menuItem.id} {...menuItem} />;
                    }
                    return <MenuItem key={menuItem.id} icon={menuItem.icon} onClick={menuItem.onClick}>{menuItem.title}</MenuItem>;
                })}
            </MenuList>
        </MenuPopover>
    );
};

const AppMenuSubMenu = (props: IAppMenuIconButtonMenuItem) => {
    const checkedValues = { checkable: props.subMenuItems.filter(i => i.isChecked).map(i => i.id) };

    return (
        <Menu checkedValues={checkedValues}>
            <MenuTrigger disableButtonEnhancement>
                <MenuItem icon={props.icon}>{props.title}</MenuItem>
            </MenuTrigger>
            <AppMenuPopover menuItems={props.subMenuItems} />
        </Menu>
    );
};

export const AppMenuIconButton = (props: IAppMenuIconButtonProps & ButtonProps) => {
    const checkedValues = { checkable: props.menuItems.filter(i => i.isChecked).map(i => i.id) };

    return (
        <div>
            <Menu checkedValues={checkedValues}>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton appearance="subtle" icon={props.icon} title={props.title} aria-label={props.title} />
                </MenuTrigger>
                <AppMenuPopover menuItems={props.menuItems} />
            </Menu>
        </div>
    );
};
