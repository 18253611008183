import * as React from "react";
import { Translations } from "../../../models/common/translations";
import { ButtonProps } from "@fluentui/react-components";
import { AppIconButton } from "./AppIconButton";
import { AppRefreshIcon } from "../icon/AppRefreshIcon";

export const RefreshIconButton = (props: ButtonProps) => (
    <AppIconButton
        {...props}
        icon={<AppRefreshIcon />}
        title={Translations.Refresh}
        aria-label={Translations.Refresh} />
);
