import * as React from "react";
import {
    Button,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    OverlayDrawer,
} from "@fluentui/react-components";
import styled from "@emotion/styled";
import { ReactNode } from "react";
import { appActions } from "../../store/app";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { CloseIconButton } from "./button/CloseIconButton";

const StyledDrawer = (props) => (
    <OverlayDrawer
        {...props}
        style={{
            height: "80%",
        }}
    />
);

const ButtonDiv = styled.div(
    {
        marginTop: "20px"
    }
);

//Component
interface IBottomDraver {
    buttonTitle: string;
    title?: string;
    children?: ReactNode;
    drawerType: string;
}

export const BottomDrawer = (props: IBottomDraver) => {
    const dispatch = useAppDispatch();
    const isOpen = useSelector((state: RootState) => state.app.activeDrawer === props.drawerType);

    const onClickBottomButton = () => {
        dispatch(appActions.openDrawer(props.drawerType));
    };

    const handleCloseClick = () => {
        dispatch(appActions.closeDrawer());
    };

    return (
        <div>
            <StyledDrawer
                key
                position={"bottom"}
                open={isOpen}
                onOpenChange={(_, { open }) => dispatch(appActions.openDrawer(open))}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <CloseIconButton
                                onClick={handleCloseClick} />
                        }
                    >
                        {props.title ?? ""}
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody >
                    {props.children}
                </DrawerBody>
            </StyledDrawer>
            <ButtonDiv>
                <Button appearance="primary" onClick={onClickBottomButton}>
                    {props.buttonTitle}
                </Button>
            </ButtonDiv>
        </div>
    );
};