import * as React from "react";
import styled from "@emotion/styled";
import { Translations } from "../../models/common/translations";
import { Card, CardFooter, Subtitle2 } from "@fluentui/react-components";

// Styles
const StyledCard = styled(Card)<{ useThumbnail: boolean }>`
  margin-top: 10px;
  height: 200px;
  display: grid;
  grid-template-columns: ${({ useThumbnail }) => (useThumbnail ? "auto 1fr" : "1fr")};
`;

const StyledDetailsContainer = styled.div`
  width: 100%;
`;

const StyledCardHeader = styled.div`
  margin-bottom: 4px;
`;

const StyledCardDetails = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`;

const StyledCardFooter = styled(CardFooter)`
  margin-top: auto;
`;

const TemplateDiv = styled.div`
  width: 131px;
  height: 177px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Thumbnail = styled.div<{ withText: boolean }>`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #333;
  position: relative;
  overflow: hidden;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  div {
    text-align: center;
  }

  ${({ withText }) =>
        withText &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

//Component
export interface IDetailCardProps {
  title: string;
  details?: React.JSX.Element;
  image?: string | null;
  useThumbnail?: boolean | false;
  footerAction: React.ReactElement;
}

export const DetailCard: React.FC<IDetailCardProps> = ({ title, details, image, footerAction, useThumbnail }) => {
    return (
        <StyledCard useThumbnail={useThumbnail}>
            {useThumbnail && (
                <TemplateDiv>
                    <Thumbnail withText={!image}>
                        {image
                            ? (
                                <img src={`data:image/jpg;base64,${image}`} alt={Translations.Thumbnail} />
                            )
                            : (
                                <div>
                                    <span>{Translations.ThumbnailIsNotAvailable}</span>
                                </div>
                            )}
                    </Thumbnail>
                </TemplateDiv>
            )}
            <StyledDetailsContainer>
                <StyledCardHeader>
                    <Subtitle2>{title}</Subtitle2>
                </StyledCardHeader>
                <StyledCardDetails>
                    {details}
                </StyledCardDetails>
                <StyledCardFooter
                    action={footerAction}
                />
            </StyledDetailsContainer>
        </StyledCard>
    );
};

export default DetailCard;