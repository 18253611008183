import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITemplate } from "../../models/template/template";
import { ITemplateCopyItem } from "../../models/template/templateCopyItem";
import { IParameterData } from "../../models/excel/parameterData";

export interface ITemplateState {
    template: ITemplate | null;
    showArchived: boolean;
    templateListId: number;
    templateCopy: { template: ITemplateCopyItem, returnUrl: string } | null;
    parameterDatas: IParameterData[];
}

const templateSlice = createSlice({
    name: "template",
    initialState: createInitialState(),
    reducers: {
        //template
        setTemplate(state: ITemplateState, action: PayloadAction<ITemplate | null>) {
            state.template = action.payload ? { ...action.payload } : null;
        },
        //showArchived
        setShowArchived(state: ITemplateState, action: PayloadAction<boolean>) {
            state.showArchived = action.payload;
        },
        //showArchived
        setTemplateListId(state: ITemplateState, action: PayloadAction<number>) {
            state.templateListId = action.payload;
        },
        //templateCopy
        setTemplateCopy(state: ITemplateState, action: PayloadAction<{ template: ITemplateCopyItem, returnUrl: string }>) {
            state.templateCopy = { ...action.payload };
        },
        //getParameterDatas
        setParameterDatas(state: ITemplateState, action: PayloadAction<IParameterData[]>) {
            state.parameterDatas = [...action.payload];
        },
    },
});

function createInitialState(): ITemplateState {
    return {
        template: null,
        showArchived: false,
        templateListId: 0,
        templateCopy: null,
        parameterDatas: []
    };
}

export const templateActions = { ...templateSlice.actions };
export const templateReducer = templateSlice.reducer;
export default templateSlice.reducer;