import { SpinButton, SpinButtonProps } from "@fluentui/react-components";
import * as React from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import FormField from "./FormField";

//Component
export interface IFormIntFieldProps {
    label: string;
    name: string;
    control: Control<any>;
    rules?: UseControllerProps["rules"];
    defaultValue?: any;
}

export const FormIntField = (props: IFormIntFieldProps & SpinButtonProps) => {
    return (
        <>
            <Controller name={props.name} control={props.control} rules={props.rules} defaultValue={props.defaultValue || 0} render={({ field }) => (
                <FormField id={field.name} label={props.label} required={props.required} >
                    <SpinButton
                        {...props}
                        {...field}
                        id={field.name}
                        type="number"
                        title={props.label}
                    />
                </FormField>
            )}
            />
        </>
    );
};

export default FormIntField;