import * as React from "react";
import { ReactNode } from "react";
import styled from "@emotion/styled";

export interface IAppStackProps {
    children?: ReactNode;
    horizontal?: boolean;
}

const StyledStack = styled.div<IAppStackProps>`
    display: flex;
    padding-bottom: 15px;
    width: auto;
    height: auto;
    box-sizing: border-box;
    flex-flow: ${props => props.horizontal ? "row" : "column"};
    justify-content: ${props => props.horizontal ? "center" : "start"};
`;

export const AppStack = (props: IAppStackProps) => {
    return (
        <StyledStack {...props}>
            {props.children}
        </StyledStack>
    );
};

export default StyledStack;