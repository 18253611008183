import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RootState, useAppDispatch } from "../../store";
import { authActions } from "../../store/auth";
import { Translations } from "../../models/common/translations";
import FormTextField from "../common/FormInput";
import MessageLine from "../common/MessageLine";
import FormLine from "../common/FormLine";
import TitleBar from "../common/TitleBar";
import { navigateBackWithReturnUrl, navigateWithSetReturnUrl } from "../../models/common/appUtils";
import SpinnerButton from "../common/SpinnerButton";
import { SettingsIconButton } from "../common/button/SettingsIconButton";

//Styles

//Component
export const Login = () => {
    const dispatch = useAppDispatch();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const authError = useSelector((state: RootState) => state.auth.error);
    const navigate = useNavigate();
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);

    const handleClose = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    };

    useEffect(() => {
        if (authUser) {
            handleClose();
        }
    }, [authUser]);

    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            username: "",
            password: ""
        }
    });
    const { isSubmitting } = formState;

    function onSubmit({ username, password } : { username: string, password: string }) {
        return dispatch(authActions.login({ username, password }));
    }

    function onHideError() {
        return dispatch(authActions.hideError());
    }

    const handleSettings = () => {
        navigateWithSetReturnUrl(navigate, "/settings", "/login");
    };

    return (
        <div>
            <TitleBar
                title={Translations.Login}
            >
                <SettingsIconButton onClick={handleSettings} />
            </TitleBar>
            <FormLine>
                <FormTextField
                    label={Translations.Username}
                    name="username"
                    control={control}
                    required
                    maxLength={50}
                    autoFocus
                    rules={{ required: Translations.UsernameIsRequired }}
                />
            </FormLine>
            <FormLine>
                <FormTextField
                    label={Translations.Password}
                    name="password"
                    control={control}
                    required
                    maxLength={50}
                    type="password"
                    rules={{ required: Translations.PasswordIsRequired }}
                />
            </FormLine>
            <FormLine horizontal>
                <SpinnerButton
                    text={Translations.Login}
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                />
            </FormLine>
            {!!(authError?.message) &&
                <FormLine horizontal>
                    <MessageLine
                        text={authError.message}
                        type={"error"}
                        onDismiss={onHideError}
                    />
                </FormLine>
            }
        </div>
    );
};