import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationSettings, IApplicationSettings } from "../../models/common/applicationSettings";
import { Translations } from "../../models/common/translations";
import TitleBar from "../common/TitleBar";
import FormLine from "../common/FormLine";
import { updateServiceRootUrl } from "../../models/services/baseService";
import FormInput from "../common/FormInput";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { handleFormDialogClose, navigateBackWithReturnUrl } from "../../models/common/appUtils";
import { SaveIconButton } from "../common/button/SaveIconButton";
import { CloseIconButton } from "../common/button/CloseIconButton";
import styled from "@emotion/styled";

//Styles
const Stack = styled.div`
    display: flex;
    align-items: center;
`;

//Component
export const AppSettings = () => {
    const navigate = useNavigate();
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);
    const [settings, _setSettings] = useState<IApplicationSettings>(ApplicationSettings.readSettings());

    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            serviceUrl: settings.serviceUrl
        }
    });

    const { isDirty } = formState;

    const closeView = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    };

    const handleClose = () => {
        handleFormDialogClose(isDirty, () => handleSubmit(onSubmit)(), closeView);
    };

    const onSubmit = async (data: { serviceUrl: string }) => {
        const newSettings = new ApplicationSettings(settings);
        newSettings.serviceUrl = data.serviceUrl;
        ApplicationSettings.saveSettings(newSettings);
        updateServiceRootUrl(newSettings.serviceUrl);
        closeView();
    };

    return (
        <div>
            <TitleBar
                title={Translations.Settings}
            >
                <Stack className="buttons">
                    <SaveIconButton onClick={handleSubmit(onSubmit)} />
                    <CloseIconButton onClick={handleClose} />
                </Stack>
            </TitleBar>
            <FormLine>
                <FormInput
                    label={Translations.AddinServiceUrl}
                    name="serviceUrl"
                    control={control}
                    maxLength={255}
                    required={true}
                    rules={{ required: Translations.AddinServiceUrlMustBeDefined }}
                />
            </FormLine>
        </div>
    );
};

export default AppSettings;