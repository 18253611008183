import { ApiSaveSuccess } from "../common/apiSaveSuccess";
import { OwnerListItems } from "../owner";
import { getApiCall, postApiCall } from "./tokenService";

export const getOwnerListItems = (): Promise<OwnerListItems> => {
    return getApiCall("owner/list", OwnerListItems);
};

export const setDefaultOwnerCode = (ownerCode: string): Promise<ApiSaveSuccess> => {
    return postApiCall("owner/setdefaultownercode", ApiSaveSuccess, JSON.stringify({ ownerCode }));
};
