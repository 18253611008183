import { Base } from "../../framework/base";

export interface IApiResponse {
    message: string;
}

export class ApiResponse implements IApiResponse {
    message: string;

    constructor();
    constructor(obj: IApiResponse);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
    }
}

export const isIApiResponse = (data: any): data is IApiResponse => {
    if (Base.isString(data)) return false;
    return data && "message" in data;
};