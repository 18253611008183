import * as React from "react";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { Translations } from "../../models/common/translations";
import { ITemplate } from "../../models/template/template";
import { templateActions } from "../../store/template";
import { useSelector } from "react-redux";
import { appTheme } from "../appTheme";
import { useCaseActions } from "../../store/useCase";
import { useEffect, useState } from "react";

//Styles
const TemplateDiv = styled.div(
    {
        width: 65,
        marginLeft: 2,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    }
);

const Thumbnail = styled.div(
    {
        "height": 92,
        "marginBottom": 5,
        "display": "flex",
        "alignItems": "center",

        "&.withText": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 92,
            border: "1px solid #333"
        },

        "img": {
            border: "1px solid #333",
            objectFit: "contain",
            width: "100%"
        },

        "div": {
            textAlign: "center"
        }
    }
);

const TemplateNameRow = styled.div`
white-space: nowrap;
overflow: hidden;
`;

const TemplateName = styled.div`
word-break: break-all;
flex-grow: 1;
`;

//Component
interface IUseCaseListItemProps {
    useCaseId: string;
    template: ITemplate;
}

export const UseCaseTemplateListItem = (props: IUseCaseListItemProps) => {
    const useCaseTemplate = useSelector((state: RootState) => state.useCase.useCaseTemplate);
    const templateTemplate = useSelector((state: RootState) => state.template.template);
    const dispatch = useAppDispatch();
    const [style, setStyle] = useState({});

    const handleSelectTemplate = () => {
        if (!props.template) return;
        if (props.useCaseId) {
            dispatch(useCaseActions.setUseCaseTemplate(props.template));
        } else {
            dispatch(templateActions.setTemplate(props.template));
        }
    };

    useEffect(() => {
        if (props.template?.id === (props.useCaseId ? useCaseTemplate : templateTemplate)?.id) {
            setStyle({ boxShadow: "0 0 2px 2px " + appTheme.semanticColors.primaryButtonBackground });
        } else {
            setStyle(null);
        }
    }, [props.template?.id, useCaseTemplate?.id, templateTemplate?.id]);

    return (
        <TemplateDiv onClick={() => handleSelectTemplate()}>
            <Thumbnail className={props.template.thumbnail ? "" : " withText"} style={props.template.thumbnail ? null : style}>
                {(props.template.thumbnail
                    ? <img src={"data:image/jpg;base64," + props.template.thumbnail} alt={Translations.Thumbnail} style={style}></img>
                    : <div><span>{props.template.thumbnailText}</span></div>
                )}
            </Thumbnail>
            <TemplateNameRow>
                <TemplateName title={props.template.name}>{props.template.name}</TemplateName>
            </TemplateNameRow>
        </TemplateDiv>
    );
};

export default UseCaseTemplateListItem;