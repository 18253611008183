import * as React from "react";
import { Field, FieldProps } from "@fluentui/react-components";
import styled from "@emotion/styled";

//Styles
const StyledField = styled(Field)`
    font-weight: var(--fontWeightSemibold);
`;

// Component
export interface IFormFieldProps extends FieldProps {
    children: React.ReactNode;

}

export const FormField = (props: IFormFieldProps) => {
    return (
        <StyledField {...props}>
            {props.children}
        </StyledField>
    );
};

export default FormField;