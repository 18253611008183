import CcType7 from "./../../../../assets/CcType07.png";
import CcType10 from "./../../../../assets/CcType10.png";
import CcType11 from "./../../../../assets/CcType11.png";
import CcType12 from "./../../../../assets/CcType12.png";
import CcType13 from "./../../../../assets/CcType13.png";
import CcType100 from "./../../../../assets/CcType100.png";
import CcTypeX from "./../../../../assets/CcTypeUnknown.png";
import { Image, ImageFit } from "@fluentui/react";
import * as React from "react";
import styled from "@emotion/styled";
import { ITemplateEditItemCc } from "../../models/template/templateEditItemCc";

//Styles
const CcContainer = styled.div`
    display: flex;
`;

const CcImage = styled(Image)`
    margin-right: 5px;
`;
//Component
export interface ITemplateEditCcLineProps {
    item: ITemplateEditItemCc;
}

export const TemplateEditCcLine = (props: ITemplateEditCcLineProps) => {
    return (
        <CcContainer>
            <CcImage
                src={props.item.type === 7
                    ? CcType7
                    : props.item.type === 10
                        ? CcType10
                        : props.item.type === 11
                            ? CcType11
                            : props.item.type === 12
                                ? CcType12
                                : props.item.type === 13
                                    ? CcType13
                                    : props.item.type === 100
                                        ? CcType100
                                        : CcTypeX}
                height={20}
                width={20}
                imageFit={ImageFit.cover}
            />
            <div>{props.item.tag}</div>
        </CcContainer>
    );
};

export default TemplateEditCcLine;
