import * as React from "react";
import { Translations } from "../../../models/common/translations";
import { ButtonProps } from "@fluentui/react-components";
import { AppIconButton } from "./AppIconButton";
import { AppRemoveIcon } from "../icon/AppRemoveIcon";

export const RemoveIconButton = (props: ButtonProps) => (
    <AppIconButton
        {...props}
        icon={<AppRemoveIcon />}
        title={Translations.Remove}
        aria-label={Translations.Remove} />
);
