import styled from "@emotion/styled";
import React from "react";
import AppStack, { IAppStackProps } from "./AppStack";

const StyledStack = styled(AppStack)`
    padding-bottom: 15px;
`;

export const FormLine = (props: IAppStackProps) => {
    return (
        <StyledStack {...props}>
            {props.children}
        </StyledStack>
    );
};

export default FormLine;