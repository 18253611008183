import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { ITemplateGroup, TemplateGroup } from "../../models/templateGroup/templateGroup";
import { getTemplateGroups, getThumbnail } from "../../models/services/templateService";
import { LanguageCode, TemplateStateCode, TemplateThumbnailSize } from "../../models/common/enums";
import { Translations } from "../../models/common/translations";
import { appActions } from "../../store/app";
import TitleBar from "../common/TitleBar";
import { useSelector } from "react-redux";
import { getUseCaseDataSetListItems } from "../../models/services/useCaseDataService";
import { callService, showErrorMessage } from "../../models/common/appUtils";
import { getUseCaseEdit } from "../../models/services/useCaseService";
import { useCaseActions } from "../../store/useCase";
import { UseCaseGenerationParameters } from "../../models/useCase/useCaseGenerationParameters";
import { UseCaseEdit } from "../../models/useCase/useCaseEdit";
import { IUseCaseEditItem } from "../../models/useCase/useCaseEditItem";
import UseCaseTemplateGroupsList from "./UseCaseTemplateGroupsList";
import { IUseCaseDataSetListItem } from "../../models/useCaseDataSet/useCaseDataSetListItem";
import UseCaseTemplate from "./UseCaseTemplate";
import { templateActions } from "../../store/template";
import { Base } from "../../framework/base";
import { ParameterDataReader } from "../../models/excel/parameterDataReader";
import { BottomDrawer } from "../common/BottomDrawer";
import UseCaseImportData from "./UseCaseImportData";
import { AppRefreshIcon } from "../common/icon/AppRefreshIcon";
import { IAppMenuIconButtonMenuItem } from "../common/button/AppMenuIconButton";

//Styles
const UseCase = styled.div(
    {
        width: "100%",
    }
);

const UseCaseDescription = styled.div`
    max-height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
`;

//Component
export const UseCaseOpen = () => {
    const mounted = useRef(false);
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const useCase = useSelector((state: RootState) => state.useCase.useCase);
    const showArchived = useSelector((state: RootState) => state.template.showArchived);
    const templateListId = useSelector((state: RootState) => state.template.templateListId);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const [useCaseDataSets, setUseCaseDataSets] = useState<IUseCaseDataSetListItem[]>([]);
    const [templateGroups, setTemplateGroups] = useState<ITemplateGroup[]>([]);
    const [filteredTemplateGroups, setFilteredTemplateGroups] = useState<ITemplateGroup[]>([]);
    const [menuItems, setMenuItems] = useState<IAppMenuIconButtonMenuItem[]>([]);
    const useCaseTemplate = useSelector((state: RootState) => state.useCase.useCaseTemplate);

    const loadThumbnail = async (oldTemplateGroups: ITemplateGroup[], templateGroupCode: string, templateCode: string) => {
        try {
            const thumnailResult = await getThumbnail(templateCode, TemplateThumbnailSize.Medium);
            const templateGroup = oldTemplateGroups.find(i => i.code === templateGroupCode);
            if (!templateGroup) return;
            const template = templateGroup.templates.find(i => i.code === templateCode);
            if (!template) return;
            if (thumnailResult.thumbnail) {
                template.thumbnail = thumnailResult.thumbnail;
            } else {
                template.thumbnailText = Translations.ThumbnailIsNotAvailable;
            }
            if (!mounted) return;
            setTemplateGroups([...oldTemplateGroups]);
        } catch (error) {
            console.log("loadThumbnail", error);
        }
    };

    const modifyFilteredTemplateGroups = () => {
        const result: ITemplateGroup[] = [];
        for (const templateGroup of templateGroups) {
            const templates = templateGroup.templates.filter(i => showArchived || i.stateCode !== TemplateStateCode.Archived);
            if (!templates.length) continue;
            const newTemplateGroup = new TemplateGroup();
            newTemplateGroup.code = templateGroup.code;
            newTemplateGroup.name = templateGroup.name;
            newTemplateGroup.templates = templates;
            result.push(newTemplateGroup);
        }
        setFilteredTemplateGroups(result);
    };

    useEffect(() => {
        modifyFilteredTemplateGroups();
    }, [templateGroups, showArchived]);

    const loadTemplateGroups = async (useCaseParam: IUseCaseEditItem) => {
        dispatch(appActions.showSpinner());
        try {
            const generationParameters = new UseCaseGenerationParameters(useCaseParam.generationParameters);
            const newTemplateGroups = await getTemplateGroups(LanguageCode.Fi, generationParameters.templateGroup);
            if (!mounted || !newTemplateGroups) return;
            setTemplateGroups(newTemplateGroups.items);
            setFilteredTemplateGroups(newTemplateGroups.items);
            if (!useCaseTemplate && newTemplateGroups.items && newTemplateGroups.items[0].templates) {
                dispatch(useCaseActions.setUseCaseTemplate(newTemplateGroups.items[0].templates[0]));
            }
            for (const templateGroup of newTemplateGroups.items) {
                for (const template of templateGroup.templates) {
                    if (!mounted) break;
                    await loadThumbnail(newTemplateGroups.items, templateGroup.code, template.code);
                }
            }
        } catch (error) {
            console.log("loadTemplateGroups", error);
            dispatch(appActions.showError(error.toString()));
        } finally {
            dispatch(appActions.hideSpinner());
        }
    };

    const loadUseCaseDataSets = async (useCaseParam: IUseCaseEditItem) => {
        const items = await callService(() => getUseCaseDataSetListItems(useCaseParam.id), false);
        if (!items) return;
        setUseCaseDataSets(items.items);
    };

    const loadUseCase = async (): Promise<UseCaseEdit> => {
        const result = await callService(() => getUseCaseEdit(id));
        if (!result) return null;
        dispatch(useCaseActions.setUseCase(result.item));
        return result;
    };

    const loadData = async () => {
        const useCaseEdit = await loadUseCase();
        if (!useCaseEdit) return;
        loadUseCaseDataSets(useCaseEdit.item);
        loadTemplateGroups(useCaseEdit.item);
    };

    useEffect(() => {
        setMenuItems([
            {
                id: "refresh",
                title: Translations.RefreshList,
                icon: <AppRefreshIcon />,
                onClick: () => {
                    loadTemplateGroups(useCase);
                    return true;
                }
            }
        ]);
    }, [authUser?.authorizationLevel ?? 9, managementMode]);

    useEffect(() => {
        loadData();
    }, [templateListId]);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    const refreshParameters = async () => {
        const parameterDataReader = new ParameterDataReader();
        const generationParameters = new UseCaseGenerationParameters(useCase.generationParameters);
        const result = await parameterDataReader.getParameterDatas(generationParameters.rootElementName,
            generationParameters.omitElementsWithPrefix, generationParameters.useCollectionElements);
        if (result.error) {
            showErrorMessage(Translations.ParameterDataReadingFailed);
        } else {
            const parseXmlErrors = result.data.filter(i => i.errorData);
            if (parseXmlErrors.length) {
                showErrorMessage(Translations.ParameterDataValidationFailed);
            }
        }
        for (const data of result.data) {
            data.id = Base.getGuid();
        }
        dispatch(templateActions.setParameterDatas(result.data));
    };

    return (
        <UseCase>
            <TitleBar
                title={Translations.UseCase}
                subTitle={useCase?.name}
                menuItems={menuItems}
            >
            </TitleBar>
            {!!useCase?.description &&
                <div>
                    <UseCaseDescription>{useCase.description}</UseCaseDescription>
                </div>
            }
            {!!useCaseDataSets.length &&

                <BottomDrawer
                    buttonTitle={Translations.ImportData}
                    title={Translations.GetData}
                    drawerType="importData">
                    <UseCaseImportData
                        items={useCaseDataSets} />
                </BottomDrawer>
            }
            <UseCaseTemplateGroupsList
                useCaseId={id}
                templateGroups={filteredTemplateGroups}
            />
            {useCaseTemplate &&
                <BottomDrawer
                    buttonTitle={Translations.Preview}
                    title={useCaseTemplate.name}
                    drawerType="templatePreview">
                    <UseCaseTemplate
                        onRefreshParameters={refreshParameters}
                    />
                </BottomDrawer>
            }
        </UseCase>
    );
};

export default UseCaseOpen;