import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { RootState, useAppDispatch } from "../../store";
import { AuthUtil } from "../../models/auth/authUtil";
import { authActions } from "../../store/auth";

export function PrivateRoute() {
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const _authTimer = useRef(0);
    const dispatch = useAppDispatch();

    const stopAuthTimer = () => {
        if (_authTimer.current) {
            window.clearInterval(_authTimer.current);
        }
    };

    useEffect(() => {
        const startTimer = () => {
            stopAuthTimer();
            _authTimer.current = window.setInterval(() => {
                if (AuthUtil.tokenIsExpired(authUser?.token)) {
                    dispatch(authActions.logout());
                }
            }, 30000);
        };
        startTimer();
        return function cleanup() {
            stopAuthTimer();
        };
    }, [authUser, dispatch]);

    return authUser
        ? <Outlet />
        : <Navigate to="/login" />;
}
