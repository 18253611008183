import * as React from "react";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { useCaseActions } from "../../store/useCase";
import { IUseCaseDataSetListItem } from "../../models/useCaseDataSet/useCaseDataSetListItem";
import UseCaseDataSetOpen from "./UseCaseDataSetOpen";
import { appTheme } from "../appTheme";
import { Card } from "@fluentui/react-components";

//Styles
const StyledCard = styled(Card)`
align-items: center;
margin: 5px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background-color: #e8e8e8;
    }
`;

//Component
export interface IUseCaseImportDataProps {
    items: IUseCaseDataSetListItem[];
}
export const UseCaseImportData = (props: IUseCaseImportDataProps) => {
    const mounted = useRef(false);
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const useCase = useSelector((state: RootState) => state.useCase.useCase);
    const [selectedItem, setSelectedItem] = React.useState<IUseCaseDataSetListItem>();

    const handleOpenUseCaseDataSet = async (useCaseDataSet: IUseCaseDataSetListItem) => {
        setSelectedItem(useCaseDataSet);
        dispatch(useCaseActions.setUseCaseDataSetOpen({ useCaseDataSet, generationParameters: useCase.generationParameters }));
    };

    const selectedStyle = React.useCallback((id) => {
        return selectedItem?.id === id
            ? { boxShadow: "0 0 2px 2px " + appTheme.semanticColors.primaryButtonBackground }
            : null;
    }, [id, selectedItem?.id]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <div >
            {props.items.map(useCaseDataSet =>
                <StyledCard size="small" key={useCaseDataSet.id} onClick={() => handleOpenUseCaseDataSet(useCaseDataSet)} style={selectedStyle(useCaseDataSet.id)}>
                    {useCaseDataSet.name}
                </StyledCard>
            )}
            {selectedItem &&
                <UseCaseDataSetOpen />
            }
        </div>
    );
};

export default UseCaseImportData;