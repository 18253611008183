import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RootState, useAppDispatch } from "../../store";
import { authActions } from "../../store/auth";
import { Translations } from "../../models/common/translations";
import FormLine from "../common/FormLine";
import TitleBar from "../common/TitleBar";
import { callService, callServiceOnSave, handleFormDialogClose, navigateBackWithReturnUrl } from "../../models/common/appUtils";
import { getOwnerListItems, setDefaultOwnerCode } from "../../models/services/ownerService";
import FormDropdown, { IFormDropdownOption } from "../common/FormDropdown";
import { Base } from "../../framework/base";
import { IOwnerListItem } from "../../models/owner";
import { SaveIconButton } from "../common/button/SaveIconButton";
import { CloseIconButton } from "../common/button/CloseIconButton";

//Styles

//Component
export const SelectOwner = () => {
    const dispatch = useAppDispatch();
    const orgOwnerCode = useSelector((state: RootState) => state.auth.authUser?.ownerCode);
    const navigate = useNavigate();
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);
    const [ownerListItems, setOwnerListItems] = useState<IOwnerListItem[]>([]);
    const [owners, setOwners] = useState<IFormDropdownOption[]>([]);

    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            ownerCode: orgOwnerCode
        }
    });

    const { isDirty } = formState;

    const closeView = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    };

    const setActiveOwner = async (ownerCode: string) => {
        const ownerListItem = ownerListItems.find(i => i.code === ownerCode);
        if (!ownerListItem) return;
        dispatch(authActions.setOwner(ownerListItem));
        await callServiceOnSave(() => setDefaultOwnerCode(ownerCode), false);
    };

    const onSubmit = ({ ownerCode } : { ownerCode: string }) => {
        if (!ownerCode) return;
        const owner = owners.find(i => i.id === ownerCode);
        if (!owner) return;
        setActiveOwner(ownerCode);
        closeView();
    };

    const handleClose = async () => {
        handleFormDialogClose(isDirty, () => handleSubmit(onSubmit)(), closeView);
    };

    const loadInitialData = async () => {
        const newOwnerListItems = await callService(() => getOwnerListItems());
        if (!newOwnerListItems) return;
        const options = newOwnerListItems.items.map(i => {
            return { id: i.code, value: i.name };
        });
        setOwnerListItems(newOwnerListItems.items);
        setOwners(options);
    };

    useEffect(() => {
        loadInitialData();
    }, []);

    useEffect(() => {
        if (owners.length === 1 && Base.isString(owners[0].id)) {
            setActiveOwner(owners[0].id);
            closeView();
        }
    }, [owners]);

    return (
        <div>
            <TitleBar
                title={Translations.SelectOwner}
            >
                {!!orgOwnerCode &&
                    <React.Fragment>
                        <SaveIconButton onClick={handleSubmit(onSubmit)} />
                        <CloseIconButton onClick={handleClose} />
                    </React.Fragment>
                }
            </TitleBar>
            <FormLine>
                <FormDropdown
                    label={Translations.Owner}
                    name="ownerCode"
                    control={control}
                    options={owners}
                />
            </FormLine>
        </div>
    );
};