import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Translations } from "../../models/common/translations";
import { IAppDialogButton } from "../../components/common/AppDialog";
import { DialogModalType } from "@fluentui/react-components";

export interface IAppState {
    spinner: { show: boolean, text: string | null };
    error: { text: string | null };
    success: { text: string | null };
    dialog: { show: boolean, type: DialogModalType, title: string, text: string, buttons?: IAppDialogButton[] }
    returnUrl: string | null;
    managementMode: boolean;
    activeDrawer: string | null;
}

const appSlice = createSlice({
    name: "app",
    initialState: createInitialState(),
    reducers: {
        //Spinner
        showSpinner(state: IAppState, action?: PayloadAction<string>) {
            state.spinner = { show: true, text: action?.payload ?? Translations.Loading };
        },
        hideSpinner(state: IAppState) {
            state.spinner = { show: false, text: null };
        },
        //Error
        showError(state: IAppState, action: PayloadAction<string>) {
            state.error = { text: action?.payload };
        },
        hideError(state: IAppState) {
            state.error = { text: null };
        },
        //Success
        showSuccess(state: IAppState, action: PayloadAction<string>) {
            state.success = { text: action?.payload };
        },
        hideSuccess(state: IAppState) {
            state.success = { text: null };
        },
        //Dialog
        showDialog(state: IAppState, action: PayloadAction<{ type: DialogModalType, title: string, text: string, buttons?: IAppDialogButton[] }>) {
            state.dialog = { show: true, type: action.payload.type, title: action.payload.title, text: action.payload.text, buttons: action.payload.buttons };
        },
        hideDialog(state: IAppState) {
            state.dialog = { show: false, type: "modal", title: null, text: null };
        },
        //ReturnUrl
        setReturnUrl(state: IAppState, action: PayloadAction<string>) {
            state.returnUrl = action.payload;
        },
        //ManagementMode
        setManagementMode(state: IAppState, action: PayloadAction<boolean>) {
            state.managementMode = action.payload;
        },
        //ActiveDrawer
        openDrawer: (state: IAppState, action: PayloadAction<string>) => {
            state.activeDrawer = action.payload;
        },
        closeDrawer: (state: IAppState) => {
            state.activeDrawer = null;
        },
    },
});

function createInitialState(): IAppState {
    return {
        spinner: { show: false, text: null },
        error: { text: null },
        success: { text: null },
        dialog: { show: false, type: "modal", title: null, text: null, buttons: null },
        returnUrl: null,
        managementMode: false,
        activeDrawer: null,
    };
}

export const appActions = { ...appSlice.actions };
export const appReducer = appSlice.reducer;
export default appSlice.reducer;