import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { MessageBar, MessageBarActions, MessageBarBody, MessageBarIntent } from "@fluentui/react-components";
import { CloseIconButton } from "./button/CloseIconButton";

//Style
const StyledMessageBar = styled(MessageBar)`
    width: 100%;
`;

//Component
export interface IMessageLineProps {
    text: string | ReactNode;
    type: MessageBarIntent;
    onDismiss: () => void;
}

export const MessageLine = (props: IMessageLineProps) => {
    const successTimeOut = useRef(0);

    useEffect(() => {
        if (props.type === "success") {
            clearTimeout(successTimeOut.current);
            successTimeOut.current = window.setTimeout(() => {
                props.onDismiss();
            }, 2500);
        }

        return () => {
            clearTimeout(successTimeOut.current);
            successTimeOut.current = 0;
        };
    }, [props.text]);

    return (
        <StyledMessageBar
            intent={props.type}
            layout="multiline"
        >
            <MessageBarBody>
                {props.text}
            </MessageBarBody>
            <MessageBarActions
                containerAction={
                    <CloseIconButton
                        onClick={props.onDismiss}
                    />
                }
            />
        </StyledMessageBar>
    );
};

export default MessageLine;