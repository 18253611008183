import * as React from "react";
import { ReactNode } from "react";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogModalType, DialogSurface, DialogTitle } from "@fluentui/react-components";

/* eslint-disable no-unused-vars */
export enum AppDialogButtonType {
    Normal,
    Primary,
    Error
}
/* eslint-enable no-unused-vars */

//Component
export interface IAppDialogButton {
    title?: any;
    disabled?: boolean;
    tooltip?: string;
    icon?: ReactNode;
    type?: AppDialogButtonType;
    onClick: (id?: string) => void;
}

interface AppDialogProps {
    open: boolean;
    dialogType: DialogModalType;
    title: string | ReactNode;
    body: string | ReactNode;
    buttons?: IAppDialogButton[];
    onClose?: () => void;
}

export const AppDialog = (props: AppDialogProps) => {
    const handleButtonClick = (button: IAppDialogButton) => {
        if (!button) return;
        if (button.disabled) return;
        button.onClick();
    };

    const dialogButtons: ReactNode[] = [];
    for (let i = 0; i < props.buttons.length; i++) {
        const button = props.buttons[i];
        if (button.onClick === null) continue;
        dialogButtons.push(<Button
            appearance={button.type === AppDialogButtonType.Primary || button.type === AppDialogButtonType.Error ? "primary" : "secondary"}
            key={i}
            title={button.tooltip}
            disabled={button.disabled}
            onClick={() => handleButtonClick(button)}>
            {button.title}
        </Button>);
    }

    return (
        <Dialog {...props}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>
                        {props.title}
                    </DialogTitle>
                    <DialogContent>
                        {props.body}
                    </DialogContent>
                    <DialogActions>
                        {dialogButtons}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog >
    );
};

export default AppDialog;