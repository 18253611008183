import * as React from "react";
import { Translations } from "../../../models/common/translations";
import { ButtonProps } from "@fluentui/react-components";
import { AppIconButton } from "./AppIconButton";
import { AppCloseIcon } from "../icon/AppCloseIcon";

export const CloseIconButton = (props: ButtonProps) => (
    <AppIconButton
        {...props}
        icon={<AppCloseIcon />}
        title={Translations.Close}
        aria-label={Translations.Close} />
);
