import { ApiError } from "../models/common/apiError";

export const callServiceFromThunk = async <T>(
    serviceCall: () => Promise<T>,
    rejectWithValue?: (value: any) => any
): Promise<T> => {
    try {
        return await serviceCall();
    } catch (e) {
        console.error(e);
        if (rejectWithValue) {
            if (e instanceof ApiError || e instanceof Error) {
                return rejectWithValue(e);
            } else if (typeof e === "string") {
                return rejectWithValue(new Error(e));
            }
        }
        throw e;
    }
};
