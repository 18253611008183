import * as React from "react";
import { Translations } from "../../../models/common/translations";
import { ButtonProps } from "@fluentui/react-components";
import { AppIconButton } from "./AppIconButton";
import { AppExcelIcon } from "../icon/AppExcelIcon";

export const UploadExcelIconButton = (props: ButtonProps) => (
    <AppIconButton
        {...props}
        icon={<AppExcelIcon />}
        title={Translations.UploadCurrentlyOpenExcel}
        aria-label={Translations.UploadCurrentlyOpenExcel} />
);
