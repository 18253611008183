import { Combobox, ComboboxProps, Option } from "@fluentui/react-components";
import * as React from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import FormField from "./FormField";
import { Base } from "../../framework/base";

//Component
export interface IFormDropdownOption {
    id: string;
    disabled?: boolean;
    value: string;
}
  
interface IFormDropdownProps {
  label?: string;
  name: string;
  control: Control<any>;
  rules?: UseControllerProps["rules"];
  defaultValue?: any;
  options: IFormDropdownOption[];
}

export const FormDropdown = (props: IFormDropdownProps & ComboboxProps) => {
    return (
        <Controller name={props.name} control={props.control} rules={props.rules} defaultValue={props.defaultValue || ""} render={({ field, fieldState }) => (
            <FormField id={field.name} label={props.label} required={props.required} validationMessage={fieldState.error ? fieldState.error.message : ""}>
                <Combobox
                    {...props}
                    {...field}
                    id={field.name}
                    title={props.label}
                    selectedOptions={[field.value]}
                    value={props.options.find((option) => option.id === field.value)?.value}
                    onOptionSelect={(_e, data) => {
                        field.onChange(data.optionValue);
                    }}
                    onBlur={field.onBlur}
                >
                    {props.options.map((option) => (
                        <Option key={option.id} value={option.id} text={Base.isString(option.value) ? option.value : ""} disabled={option.disabled}>
                            {option.value}
                        </Option>
                    ))}
                </Combobox>
            </FormField>
        )}
        />
    );
};

export default FormDropdown;