import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RootState } from "../../store";
import { Translations } from "../../models/common/translations";
import FormInput from "../common/FormInput";
import FormLine from "../common/FormLine";
import TitleBar from "../common/TitleBar";
import { callService } from "../../models/common/appUtils";
import SpinnerButton from "../common/SpinnerButton";
import { copyTemplate } from "../../models/services/templateService";
import { CloseIconButton } from "../common/button/CloseIconButton";
import styled from "@emotion/styled";

//Styles
const Stack = styled.div`
    display: flex;
    align-items: center;
`;

//Component
export const TemplateCopy = () => {
    const templateCopy = useSelector((state: RootState) => state.template.templateCopy);
    const navigate = useNavigate();

    const handleClose = () => {
        navigate(templateCopy.returnUrl);
    };

    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState, reset } = useForm({
        defaultValues: {
            code: (templateCopy?.template.code ?? "") + "_copy",
            name: (templateCopy?.template.name ?? "") + " copy"
        }
    });
    const { isSubmitting } = formState;

    useEffect(() => {
        if (!templateCopy) return;
        reset({
            code: templateCopy.template.code + "_copy",
            name: templateCopy.template.name + " copy"
        });
    }, [templateCopy]);

    const onSubmit = async ({ code, name } : { code: string, name: string }) => {
        const result = await callService(() => copyTemplate(templateCopy.template.id, code, name), true, true);
        if (!result) return;
        handleClose();
    };

    if (!templateCopy) return null;

    return (
        <div>
            <TitleBar
                title={Translations.CopyTemplate}
            >
                <Stack className="buttons">
                    <CloseIconButton onClick={handleClose} />
                </Stack>
            </TitleBar>
            <FormLine>
                <FormInput
                    label={Translations.NewTemplateCode}
                    name="code"
                    control={control}
                    required
                    maxLength={100}
                    autoFocus
                    //rules={{ required: Translations.CodeIsRequired }}
                    rules={{
                        required: Translations.CodeIsRequired,
                        pattern: {
                            value: /^[^\\^?&/+<>: ]*[^\\^?&/.+<>: ]$/i,
                            message: Translations.TemplateCodeInvalid
                        }
                    }}
                />
            </FormLine>
            <FormLine>
                <FormInput
                    label={Translations.NewTemplateName}
                    name="name"
                    control={control}
                    required
                    maxLength={100}
                    rules={{ required: Translations.NameIsRequired }}
                />
            </FormLine>
            <FormLine horizontal>
                <SpinnerButton
                    text={Translations.Copy}
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                />
            </FormLine>
        </div>
    );
};