import { callService, callServiceOnSave, hasPermission, navigateWithSetReturnUrl } from "../../models/common/appUtils";
import { getOwnerListItems, setDefaultOwnerCode } from "../../models/services/ownerService";
import { IOwnerListItem } from "../../models/owner";
import { authActions } from "../../store/auth";
import HomeView from "./HomeView";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { Translations } from "../../models/common/translations";
import TitleBar from "../common/TitleBar";
import { useSelector } from "react-redux";
import TemplateGroups from "../template/TemplateGroups";
import UseCases from "../useCase/useCases";
import { appActions } from "../../store/app";
import { AuthorizationLevel, TabType } from "../../models/common/enums";
import { Tab, TabList } from "@fluentui/react-components";
import AppMenuButton, { AppMenuItem } from "../common/AppMenuButton";
import { AppSettingsIcon } from "../common/icon/AppSettingsIcon";
import { IAppMenuIconButtonMenuItem } from "../common/button/AppMenuIconButton";

//Styles
const HomeContainer = styled.div`
    width: 100%;
`;

//Component
export const Home = () => {
    const [menuProps, setMenuProps] = useState<AppMenuItem[]>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const [menuItems, setMenuItems] = useState<IAppMenuIconButtonMenuItem[]>([]);
    const { tab } = useParams();
    const [selectedKey, setSelectedKey] = useState<string>(tab || TabType.Home);

    useEffect(() => {
        setSelectedKey(tab || TabType.Home);
    }, [tab]);

    const handleLinkClick = (data: string) => {
        const newTab = data;
        navigate("/" + newTab);
    };

    const changeOwner = async (owner: IOwnerListItem) => {
        if (!owner) return;
        dispatch(authActions.setOwner(owner));
        await callServiceOnSave(() => setDefaultOwnerCode(owner.code), false);
    };

    const loadInitialData = async () => {
        if (!authUser.hasMultipleOwners) return;
        const newOwnerListItems = await callService(() => getOwnerListItems());
        if (!newOwnerListItems) return;
        const newMenuProps: AppMenuItem[] =
            newOwnerListItems.items.map(i => {
                return {
                    key: i.code,
                    text: i.name,
                    onClick: () => {
                        changeOwner(i);
                        return true;
                    }
                };
            });
        setMenuProps(newMenuProps);
    };

    const toggleManagementMode = () => {
        dispatch(appActions.setManagementMode(!managementMode));
    };

    useEffect(() => {
        const items: IAppMenuIconButtonMenuItem[] = [];

        if (hasPermission(authUser, AuthorizationLevel.User)) {
            items.push(
                {
                    id: "managementMode",
                    title: Translations.ManagementMode,
                    isCheckable: true,
                    isChecked: managementMode,
                    onClick: () => {
                        toggleManagementMode();
                        return true;
                    }
                },
            );
        }

        items.push(
            {
                id: "settings",
                title: Translations.Settings,
                icon: <AppSettingsIcon />,
                onClick: () => {
                    navigateWithSetReturnUrl(navigate, "/settings", "/");
                    return true;
                }
            },
        );
        setMenuItems(items);
    }, [managementMode]);

    useEffect(() => {
        loadInitialData();
    }, []);

    return (
        <HomeContainer>
            <TitleBar
                title={!managementMode ? Translations.Home : ""}
                menuItems={menuItems}
            >
                {!authUser.hasMultipleOwners &&
                    <span>{authUser.ownerName}</span>
                }
                {authUser.hasMultipleOwners &&
                    <AppMenuButton text={authUser.ownerName} menuProps={menuProps} />
                }
            </TitleBar>
            {managementMode
                ? <div>
                    <TabList
                        onTabSelect={(_, d) => handleLinkClick(d.value as string)}
                        selectedValue={selectedKey}
                    >
                        <Tab name={Translations.Home} value={TabType.Home} aria-label="Home">
                            {Translations.Home}
                        </Tab>
                        <Tab name={Translations.UseCase} value={TabType.UseCases} aria-label="UseCases">
                            {Translations.UseCase}
                        </Tab>
                        <Tab name={Translations.Templates} value={TabType.Templates} aria-label="Templates">
                            {Translations.Templates}
                        </Tab>
                    </TabList>
                    {selectedKey === TabType.Home && <HomeView />}
                    {selectedKey === TabType.UseCases && <UseCases tabKey={TabType.UseCases} />}
                    {selectedKey === TabType.Templates && <TemplateGroups tabKey={TabType.Templates} />}
                </div>
                : <HomeView />
            }
        </HomeContainer>
    );
};

export default Home;