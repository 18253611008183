import { Base } from "../../framework/base";
import { store } from "../../store";
import { authActions } from "../../store/auth";
import { AuthResponse } from "../auth/authResponse";
import { AuthUser } from "../auth/authUser";
import { AuthUtil } from "../auth/authUtil";
import { getRootUrl, handleError, handleFileBlobResponse, handleResponse } from "./baseService";

export const initialLogin = (username: string, password: string): Promise<AuthResponse> => {
    const requestOptions = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json;charset=utf-8",
            "Authorization": "Basic " + Base.b64EncodeUnicode(username) + ":" + Base.b64EncodeUnicode(password),
        },
    };
    return fetch(getRootUrl() + "token", requestOptions)
        .then(handleResponse, handleError)
        .then((data) => {
            if (!data?.token) {
                throw new Error("Invalid return data!");
            }
            return data;
        });
};

const getBearerToken = (token: string): string => {
    return "Bearer " + token;
};

const authorizationHeaderValue = (): string => {
    const token = AuthUtil.token;
    if (!token) return "";
    return getBearerToken(token);
};

const authorizationHeaderRefreshTokenValue = () => {
    const token = AuthUtil.refreshToken;
    if (!token) return "";
    return getBearerToken(token);
};

const ownerHeaderValue = (): string => {
    return AuthUtil.ownerCode;
};

const refreshLogin = (): Promise<AuthResponse | null> => {
    const requestOptions = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json;charset=utf-8",
            "Authorization": authorizationHeaderRefreshTokenValue(),
        },
    };
    return fetch(getRootUrl() + "token/refresh", requestOptions)
        .then(handleResponse, handleError)
        .then((data) => {
            if (!data?.token) {
                return null;
            }
            return new AuthUser(data);
        });
};

const refreshAuthentication = async (force: boolean = false): Promise<void> => {
    if (force || AuthUtil.authNeedsRefresh()) {
        try {
            const auth = await refreshLogin();
            store.dispatch(authActions.update(auth));
        } catch (e) {
            store.dispatch(authActions.logout());
            throw e;
        }
    } else {
        return new Promise((resolve) => {
            resolve();
        });
    }
};

const getJsonHeaders = (body: string | null = null): HeadersInit => {
    return {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=utf-8",
        "Content-Length": (body ? body.length : 0).toString(10),
        "Authorization": authorizationHeaderValue(),
        "OwnerCode": ownerHeaderValue()
    };
};

const getRequestOptions = () => {
    return {
        headers: getJsonHeaders()
    };
};

const postRequestOptions = (body: string = null) => {
    return body
        ? {
            method: "POST",
            headers: getJsonHeaders(body),
            body
        }
        : {
            method: "POST",
            headers: getJsonHeaders()
        };
};

export const getApiCallUnTyped = async (url: string): Promise<any> => {
    await refreshAuthentication();
    return fetch(getRootUrl() + url, getRequestOptions())
        .then(handleResponse, handleError)
        .catch(handleError);
};

export const getApiCall = async <T>(url: string, type: (new (...args: any[]) => T)): Promise<T> => {
    return getApiCallUnTyped(url)
        .then(data => {
            // ReSharper disable InconsistentNaming
            /* eslint-disable new-cap */
            return type
                ? !data ? new type() : new type(data)
                : !data ? <T><unknown>0 : <T>(data);
            /* eslint-enable new-cap */
        });
};

export const postApiCallUnTyped = async (url: string, body: string = null): Promise<any> => {
    await refreshAuthentication();
    return await fetch(getRootUrl() + url, postRequestOptions(body))
        .then(handleResponse, handleError)
        .catch(handleError);
};

export const postApiCall = async <T>(url: string, type: (new (...args: any[]) => T), body: string | null = null): Promise<T> => {
    return postApiCallUnTyped(url, body)
        .then(data => {
            // ReSharper disable InconsistentNaming
            /* eslint-disable new-cap */
            return type
                ? !data ? new type() : new type(data)
                : !data ? <T><unknown>0 : <T>(data.result);
            /* eslint-enable new-cap */
        });
};

export const getFileBlobApiCall = async (url: string): Promise<Blob> => {
    await refreshAuthentication();
    return await fetch(getRootUrl() + url, getRequestOptions())
        .then(handleFileBlobResponse, handleError);
};

export const postFileBlobApiCall = async (url: string, body: string = null): Promise<Blob> => {
    await refreshAuthentication();
    return await fetch(getRootUrl() + url, postRequestOptions(body))
        .then(handleFileBlobResponse, handleError);
};