import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Translations } from "../../models/common/translations";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { getUseCaseListItems, removeUseCase } from "../../models/services/useCaseService";
import { callService, callServiceOnRemove, hasPermission, navigateWithSetReturnUrl, showConfirmationDialog, useCaseCanBeAdded } from "../../models/common/appUtils";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { Base } from "../../framework/base";
import UseCasesListItem from "./UseCasesListItem";
import { useCaseActions } from "../../store/useCase";
import DetailCard from "../common/DetailCard";
import { AuthorizationLevel, ConfirmationDialogResult } from "../../models/common/enums";
import { EditIconButton } from "../common/button/EditIconButton";
import { RemoveIconButton } from "../common/button/RemoveIconButton";
import { Body1Strong, Text } from "@fluentui/react-components";
import { AppMenuIconButton, IAppMenuIconButtonMenuItem } from "../common/button/AppMenuIconButton";
import { AppRefreshIcon } from "../common/icon/AppRefreshIcon";
import { AppAddIcon } from "../common/icon/AppAddIcon";
import { AppMoreIcon } from "../common/icon/AppMoreIcon";

//Styles
const Stack = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;

const UseCase = styled.div(
    {
        height: "calc(100vh - " + (380).toString(10) + "px)",
        overflowY: "auto",
        overflowX: "hidden"
    }
);

//Component
export const UseCasesList = ({ tabKey }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const [useCaseListItems, setUseCaseListItems] = useState<IUseCaseListItem[]>([]);
    const selectedUseCase = useSelector((state: RootState) => state.useCase.selectedUseCase);
    const returnUrl = `/${tabKey}`;
    const [menuItems, setMenuItems] = useState<IAppMenuIconButtonMenuItem[]>([]);
    const [footerAction, setFooterAction] = React.useState<React.ReactElement>(null);

    const loadInitialData = async () => {
        const newUseCaseListItems = await callService(() => getUseCaseListItems());
        if (!newUseCaseListItems) return;
        setUseCaseListItems(newUseCaseListItems.items);
        if (!selectedUseCase) dispatch(useCaseActions.setSelectedUseCase(newUseCaseListItems.items[0]));
    };

    const handleAddUseCase = () => {
        navigateWithSetReturnUrl(navigate, "/usecaseedit/" + Base.emptyGuid, returnUrl);
    };

    const handleEdit = () => {
        navigateWithSetReturnUrl(navigate, "/usecaseedit/" + selectedUseCase.id, returnUrl);
    };

    const handleRemove = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.UseCaseRemoveConfirmation, selectedUseCase.name), Translations.RemoveUseCase);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeUseCase(selectedUseCase.id));
        if (!dbResult) return;
        refreshList();
    };

    useEffect(() => {
        loadInitialData();
    }, [authUser?.ownerCode]);

    const refreshList = async () => {
        await loadInitialData();
    };

    useEffect(() => {
        const newMenuItems: IAppMenuIconButtonMenuItem[] = [
            {
                id: "refresh",
                title: Translations.RefreshList,
                icon: <AppRefreshIcon />,
                onClick: () => {
                    refreshList();
                    return true;
                }
            }
        ];
        if (useCaseCanBeAdded(authUser) && managementMode) {
            newMenuItems.push({
                id: "add",
                title: Translations.AddUseCase,
                icon: <AppAddIcon />,
                onClick: () => {
                    handleAddUseCase();
                    return true;
                }
            });
        }
        setMenuItems(newMenuItems);
    }, [authUser, managementMode]);

    useEffect(() => {
        if (hasPermission(authUser, AuthorizationLevel.User)) {
            setFooterAction(<div>
                <EditIconButton onClick={handleEdit} />
                <RemoveIconButton onClick={handleRemove} />
            </div>);
        } else {
            setFooterAction(null);
        }
    }, [selectedUseCase, authUser]);

    return (
        <React.Fragment>
            {managementMode
                ? <Stack>
                    <AppMenuIconButton title={Translations.More} icon={<AppMoreIcon />} menuItems={menuItems} />
                </Stack>
                : null}
            <UseCase>
                {useCaseListItems.map((item) =>
                    <UseCasesListItem
                        key={item.id}
                        item={item}
                    />
                )}
            </UseCase>
            {selectedUseCase &&
                <DetailCard
                    footerAction={footerAction}
                    details={
                        selectedUseCase?.description
                            ? (
                                <>
                                    <Body1Strong>{Translations.Description}</Body1Strong>
                                    <Text>{selectedUseCase.description}</Text>
                                </>
                            )
                            : null
                    }
                    image={selectedUseCase.image}
                    useThumbnail
                    title={selectedUseCase.name}
                />
            }
        </React.Fragment>
    );
};

export default UseCasesList;