import * as React from "react";
import { Translations } from "../../../models/common/translations";
import { ButtonProps } from "@fluentui/react-components";
import { AppSaveIcon } from "../icon/AppSaveIcon";
import { AppIconButton } from "./AppIconButton";

export const SaveIconButton = (props: ButtonProps) => (
    <AppIconButton
        {...props}
        icon={<AppSaveIcon />}
        title={Translations.Save}
        aria-label={Translations.Save} />
);
