import { Tooltip } from "@fluentui/react-components";
import * as React from "react";
import { ReactElement } from "react";

export const AppTooltip = (props: { children?: ReactElement, text: string }) => {
    if (!props.text) {
        return <>{props.children}</>;
    }
    return (
        <Tooltip
            content={props.text}
            relationship="label"
        >
            <div>
                {props.children}
            </div>
        </Tooltip>
    );
};

export default AppTooltip;