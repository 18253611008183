import * as React from "react";
import styled from "@emotion/styled";
import AppTooltip from "../common/AppTooltip";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { useCaseActions } from "../../store/useCase";
import { appTheme } from "../appTheme";
import { Card } from "@fluentui/react-components";

//Styles
const StyledCard = styled(Card)`
margin: 5px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background-color: #e8e8e8;
    }
`;

//Component
export interface IUseCasesListItemProps {
    item: IUseCaseListItem;
}

export const UseCasesListItem = (props: IUseCasesListItemProps) => {
    const dispatch = useAppDispatch();

    const selectedUseCase = useSelector((state: RootState) => state.useCase.selectedUseCase);
    const selectedStyle = React.useCallback(() => {
        return selectedUseCase?.id === props.item?.id
            ? { boxShadow: "0 0 2px 2px " + appTheme.semanticColors.primaryButtonBackground }
            : null;
    }, [props.item?.id, selectedUseCase?.id]);

    return (
        <StyledCard size="small" onClick={() => dispatch(useCaseActions.setSelectedUseCase(props.item))} style={selectedStyle()}>
            <AppTooltip
                text={props.item.tip}
            >
                <div>{props.item.name}</div>
            </AppTooltip>
        </StyledCard>
    );
};

export default UseCasesListItem;
