import { createLightTheme, createDarkTheme } from "@fluentui/react-components";

// Theme
export const appTheme = {
    semanticColors: {
        primaryButtonBackground: "#FBC010",
        primaryButtonText: "#333",
        primaryButtonBackgroundHovered: "#ecb204",
        primaryButtonTextHovered: "#333"
    }
};

const myBrand = {
    "10": "#004578",
    "20": "#325e63",
    "30": "#4b6a59",
    "40": "#64764e",
    "50": "#7e8344",
    "60": "#978f3a",
    "70": "#b09b2f",
    "80": "#FBC010",
    "90": "#fac528",
    "100": "#f9cb3f",
    "110": "#f7d057",
    "120": "#f6d66e",
    "130": "#f5db86",
    "140": "#f4e09e",
    "150": "#f3e6b5",
    "160": "#eff6fc"
};

export const light = createLightTheme(myBrand);
export const dark = createDarkTheme(myBrand);