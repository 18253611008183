import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useAppDispatch } from "../../store";
import { appActions } from "../../store/app";
import { MessageBar, MessageBarActions, MessageBarBody, MessageBarIntent } from "@fluentui/react-components";
import { CloseIconButton } from "./button/CloseIconButton";

//Style
const MessageContainer = styled.div(
    {
        "position": "fixed",
        "bottom": 0,
        "left": 0,
        "right": 0,
        "top": 0,
        "zIndex": "1000001",

        "& > *": {
            marginLeft: "5%",
            marginRight: "5%",
            width: "90%",
            position: "absolute",
            top: "30%"
        },
    }
);

//Component
export interface IMessageProps {
    text: string | ReactNode;
    type: MessageBarIntent;
}

export const Message = (props: IMessageProps) => {
    const successTimeOut = useRef(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (props.type === "success") {
            clearTimeout(successTimeOut.current);
            successTimeOut.current = window.setTimeout(() => {
                dispatch(appActions.hideSuccess());
            }, 2500);
        }

        return () => {
            clearTimeout(successTimeOut.current);
            successTimeOut.current = 0;
        };
    }, [props.text]);

    const handleCloseClick = () => {
        if (props.type === "error") {
            dispatch(appActions.hideError());
        } else if (props.type === "success") {
            dispatch(appActions.hideSuccess());
        }
    };

    return (
        <MessageContainer onClick={handleCloseClick}>
            <MessageBar
                intent={props.type}
                layout="multiline"
            >
                <MessageBarBody>
                    {props.text}
                </MessageBarBody>
                <MessageBarActions
                    containerAction={
                        <CloseIconButton
                            onClick={handleCloseClick}
                        />
                    }
                />
            </MessageBar>
        </MessageContainer>
    );
};

export default Message;