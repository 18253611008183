import * as React from "react";
import styled from "@emotion/styled";
import { ReactNode } from "react";

//Style
const TextContainer = styled.div`
  padding: 5px 10px;
  border-radius: 15px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: ${(props) => !!props.onClick && "pointer"};
`;
//Component
export interface IStateTextProps {
  text: string | ReactNode;
  color: string;
  onClick?: () => void;
}

export const StateText = (props: IStateTextProps) => {
    return (
        <TextContainer
            onClick={props.onClick}
            style={{ backgroundColor: props.color }}
        >
            {props.text}
        </TextContainer>
    );
};

export default StateText;