import * as React from "react";
import { Translations } from "../../../models/common/translations";
import { ButtonProps } from "@fluentui/react-components";
import { AppIconButton } from "./AppIconButton";
import { AppSettingsIcon } from "../icon/AppSettingsIcon";

export const SettingsIconButton = (props: ButtonProps) => (
    <AppIconButton
        {...props}
        icon={<AppSettingsIcon />}
        title={Translations.Settings}
        aria-label={Translations.Settings} />
);
