import styled from "@emotion/styled";
import { Spinner } from "@fluentui/react-components";
import * as React from "react";

//Style
const StyledSpinner = styled(Spinner)`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000002;
`;

//Component
export const LoadingIndicator = (props: { text: string }) => {
    return (
        <StyledSpinner size={"large"} label={props.text} />
    );
};

export default LoadingIndicator;