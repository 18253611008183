import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Form from "@rjsf/fluent-ui";
import validator from "@rjsf/validator-ajv8";
import { Translations } from "../../models/common/translations";
import { callService } from "../../models/common/appUtils";
import { ExcelFileSettings } from "../../models/excel/excelFileSettings";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { UseCaseGenerationParameters } from "../../models/useCase/useCaseGenerationParameters";
import SpinnerButton from "../common/SpinnerButton";
import { getUseCaseDataSetData, getUseCaseDataSetEdit } from "../../models/services/useCaseDataService";
import { ExcelHelper } from "../../models/excel/excelHelper";
import { appActions } from "../../store/app";
import styled from "@emotion/styled";
import { IUseCaseDataSetEdit } from "../../models/useCaseDataSet/useCaseDataSetEdit";

//Styles
const UseCaseDataSetDescription = styled.div(
    {
        marginTop: "10px",
        width: "100%"
    }
);

const JsonForm = styled(Form)`
    & > div {
        padding: 0;
    }
`;

//Component
const UseCaseDataSetOpen = () => {
    const useCaseDataSetOpen = useSelector((state: RootState) => state.useCase.useCaseDataSetOpen);
    const dispatch = useAppDispatch();
    const [useCaseDataSetEdit, setUseCaseDataSetEdit] = useState<IUseCaseDataSetEdit>(null);
    const [jsonFormData, setJsonFormData] = React.useState(null);
    const { handleSubmit, formState, reset } = useForm({
        defaultValues: {
            name: useCaseDataSetOpen?.useCaseDataSet.name ?? "",
        }
    });

    const { isSubmitting } = formState;

    const loadUseCaseDataSet = async () => {
        if (!useCaseDataSetOpen) return;
        const settings = await ExcelFileSettings.readSettings();
        const edit = await callService(() => getUseCaseDataSetEdit(settings.useCaseId, useCaseDataSetOpen.useCaseDataSet.id));
        if (!edit) return;
        setUseCaseDataSetEdit(edit);
        reset({
            name: useCaseDataSetOpen.useCaseDataSet.name
        });
    };

    const closeView = () => {
        dispatch(appActions.closeDrawer());
    };

    useEffect(() => {
        loadUseCaseDataSet();
    }, [useCaseDataSetOpen]);

    const onSubmit = async () => {
        const settings = await ExcelFileSettings.readSettings();
        const items = await callService(() => getUseCaseDataSetData(settings.useCaseId, useCaseDataSetOpen.useCaseDataSet.id, jsonFormData ? JSON.stringify(jsonFormData) : null), false);
        if (!items) return;
        const generationParameters = new UseCaseGenerationParameters(useCaseDataSetOpen.generationParameters);
        const errors = await ExcelHelper.setDataToTables(generationParameters.omitElementsWithPrefix, items.items, true);
        if (!errors) {
            closeView();
            return;
        }
        dispatch(appActions.showError(errors));
    };

    if (!useCaseDataSetEdit) return null;

    return (
        <div>
            <UseCaseDataSetDescription>{useCaseDataSetOpen.useCaseDataSet.description ?? ""}</UseCaseDataSetDescription>
            {!!useCaseDataSetEdit.item.schema &&
                <JsonForm
                    schema={useCaseDataSetEdit.item.schema}
                    uiSchema={useCaseDataSetEdit.item.uiSchema}
                    formData={jsonFormData}
                    onChange={(e) => {
                        setJsonFormData(e.formData);
                    }}
                    validator={validator}
                >
                    <SpinnerButton
                        text={Translations.Download}
                        isSubmitting={isSubmitting}
                        onClick={handleSubmit(onSubmit)}
                    />
                </JsonForm>
            }
            {!useCaseDataSetEdit.item.schema &&
                <SpinnerButton
                    text={Translations.Download}
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                />
            }
        </div>
    );
};

export default UseCaseDataSetOpen;