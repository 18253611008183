import * as React from "react";
import { Translations } from "../../../models/common/translations";
import { ButtonProps } from "@fluentui/react-components";
import { AppIconButton } from "./AppIconButton";
import { AppEyeOffIcon } from "../icon/AppEyeOffIcon";
import { AppEyeIcon } from "../icon/AppEyeIcon";

interface IArchivedIconButtonProps {
    isArchived: boolean;
}

export const ArchivedIconButton = (props: IArchivedIconButtonProps & ButtonProps) => (
    <AppIconButton
        {...props}
        appearance="subtle"
        icon={!props.isArchived ? <AppEyeOffIcon /> : <AppEyeIcon />}
        title={!props.isArchived ? Translations.Hide : Translations.Show}
        aria-label={!props.isArchived ? Translations.Hide : Translations.Show} />
);
