import {
    Image,
    makeStyles,
    Carousel,
    CarouselCard,
    CarouselNavContainer,
    CarouselSlider,
    Button,
    Text
} from "@fluentui/react-components";
import { ArrowDownloadRegular } from "@fluentui/react-icons";
import * as React from "react";
import { Translations } from "../../models/common/translations";

//Styles
const useClasses = makeStyles({
    carousel: {
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        gridTemplateRows: "1fr auto",
        gap: "10px",
        placeItems: "center",
    },
    carouselNav: {
        alignContent: "center",
    },
    img: {
        border: "solid 1px #979797",
    },
    footer: {
        display: "flex",
        justifyContent: "center"
    }

});

//Component
export interface IImageCarouselImage {
    id: string;
    image: string;
    tooltip: string;
}
interface IImageCarouselProps {
    images: string[];
    footerSuffix?: React.ReactNode;
    onDownload: () => void;
}

export const ImageCarousel = (props: IImageCarouselProps) => {
    const IMAGES = props.images;
    const [activeIndex, setActiveIndex] = React.useState(1);
    const classes = useClasses();

    return (
        <div>
            <Carousel
                activeIndex={activeIndex}
                groupSize={1}
                onActiveIndexChange={(_, data) => setActiveIndex(data.index)}>
                {IMAGES.length > 0 &&
                    <CarouselNavContainer
                        layout="inline"
                        next={{ "aria-label": "go to next" }}
                        prev={{ "aria-label": "go to prev" }}
                    >
                        <Text className={classes.carouselNav}>
                            {activeIndex + 1}
                        </Text>
                    </CarouselNavContainer>
                }
                <CarouselSlider>
                    {IMAGES.map((imageSrc, index) => (
                        <CarouselCard
                            key={`image-${index}`}
                            aria-label={`${index + 1} ${Translations.Of} ${IMAGES.length}`}
                        >
                            <Image className={classes.img} fit="cover" src={imageSrc} role="presentation" />
                        </CarouselCard>
                    ))}
                </CarouselSlider>
                {IMAGES.length > 0 &&
                    <div className={classes.footer}>
                        {!!props.onDownload &&
                            <Button
                                appearance="transparent"
                                iconPosition="before"
                                icon={<ArrowDownloadRegular />}
                                disabled={!props.images.length}
                                onClick={props.onDownload}
                                title={Translations.Download}>
                                {Translations.Download}
                            </Button>
                        }
                        {props.footerSuffix}
                    </div>
                }
            </Carousel>

        </div>
    );
};

export default ImageCarousel;