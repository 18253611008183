import * as React from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import { Input, InputProps } from "@fluentui/react-components";
import FormField from "./FormField";

//Component
export interface IFormFieldProps {
    label: string;
    control: Control<any>;
    rules?: UseControllerProps["rules"];
}

export const FormInput = (props: IFormFieldProps & InputProps) => {
    return (
        <Controller name={props.name} control={props.control} rules={props.rules} defaultValue={props.defaultValue || ""} render={({ field, fieldState }) => (
            <FormField id={field.name} label={props.label} required={props.required} validationMessage={fieldState.error ? fieldState.error.message : ""}>
                <Input
                    {...props}
                    {...field}
                    id={field.name}
                />
            </FormField>
        )}
        />
    );
};

export default FormInput;