import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Translations } from "../../models/common/translations";
import FormInput from "../common/FormInput";
import FormLine from "../common/FormLine";
import TitleBar from "../common/TitleBar";
import { IUseCaseEdit } from "../../models/useCase/useCaseEdit";
import { getUseCaseEdit, getUseCaseExcelFile, removeUseCase, saveUseCase } from "../../models/services/useCaseService";
import { callService, callServiceOnRemove, callServiceOnSave, handleFormDialogClose, navigateBackWithReturnUrl, showConfirmationDialog, showErrorMessage, showSuccessMessage } from "../../models/common/appUtils";
import FormIntField from "../common/FormIntField";
import FormDropdown, { IFormDropdownOption } from "../common/FormDropdown";
import { Base } from "../../framework/base";
import { FormImageField } from "../common/FormImageField";
import styled from "@emotion/styled";
import { ExcelFileSettings } from "../../models/excel/excelFileSettings";
import FormBoolenField from "../common/FormBoolenField";
import saveAs from "file-saver";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { UseCaseGenerationParameters } from "../../models/useCase/useCaseGenerationParameters";
import { FormFileField } from "../common/FormFileField";
import { ConfirmationDialogResult, LanguageCode } from "../../models/common/enums";
import { ExcelHelper } from "../../models/excel/excelHelper";
import { Base64 } from "js-base64";
import { xlsxContentType } from "../../models/common/consts";
import { appActions } from "../../store/app";
import { getTemplateGroups } from "../../models/services/templateService";
import { SaveIconButton } from "../common/button/SaveIconButton";
import { RemoveIconButton } from "../common/button/RemoveIconButton";
import { CloseIconButton } from "../common/button/CloseIconButton";
import FormTextArea from "../common/FormTextArea";

//Styles
const UseCaseEditName = styled.div(
    {
        marginLeft: "10px",
        flexGrow: 1
    }
);

const UseCaseEditContent = styled.div(
    {
        height: "calc(100vh - " + (94).toString(10) + "px)",
        overflowY: "auto",
        overflowX: "hidden"
    }
);

const Stack = styled.div`
    display: flex;
    align-items: center;
`;

//Component
interface IFormData {
    name: string;
    description: string;
    tip: string;
    sortOrder: number;
    useCaseDataId: string;
    //Aiddocs
    ownerCode: string;
    username: string;
    password: string;
    context: string;
    templateGroup: string;
    //Parameter Data
    rootElementName: string;
    omitElementsWithPrefix: string;
    useCollectionElements: boolean;
    allowMultiple: boolean;
    autoOpenTaskPane: boolean;
}

const UseCaseEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);
    const [useCaseEdit, setUseCaseEdit] = useState<IUseCaseEdit>(null);
    const [useCaseDatas, setUseCaseDatas] = useState<IFormDropdownOption[]>([]);
    const [tgOptions, setTgOptions] = useState<IFormDropdownOption[]>([]);
    const [image, setImage] = useState<string>(null);
    const [excel, setExcel] = useState<string>(null);
    const [excelFile, setExcelFile] = useState<File>(null);
    const [excelName, setExcelName] = useState<string>(null);
    const dispatch = useAppDispatch();

    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState, reset } = useForm({
        defaultValues: {
            name: "",
            description: "",
            tip: "",
            sortOrder: 0,
            useCaseDataId: Base.emptyGuid,
            context: "",
            templateGroup: "",
            rootElementName: "",
            omitElementsWithPrefix: "",
            useCollectionElements: false,
            allowMultiple: false,
            autoOpenTaskPane: false,
        }
    });

    const { isDirty } = formState;

    const loadUseCase = async () => {
        const edit = await callService(() => getUseCaseEdit(id));
        if (!edit) return;
        setUseCaseEdit(edit);
        loadTemplateGroups();
        const item = edit.item;
        setImage(item.image ? "data:image/jpg;base64," + item.image : null);
        const generationParameters = new UseCaseGenerationParameters(item.generationParameters);
        reset({
            name: item.name ?? "",
            description: item.description ?? "",
            tip: item.tip ?? "",
            sortOrder: item.sortOrder ?? 0,
            useCaseDataId: item.useCaseDataId ? item.useCaseDataId : Base.emptyGuid,
            context: generationParameters.context,
            templateGroup: generationParameters.templateGroup,
            rootElementName: generationParameters.rootElementName,
            omitElementsWithPrefix: generationParameters.omitElementsWithPrefix,
            useCollectionElements: generationParameters.useCollectionElements,
            allowMultiple: generationParameters.allowMultiple,
            autoOpenTaskPane: generationParameters.autoOpenTaskPane,
        });
        const options = edit.useCaseDatas.map(i => {
            return { id: i.id, value: i.name };
        });
        options.unshift({ id: Base.emptyGuid, value: Translations.NotInUse });
        setUseCaseDatas(options);
        setExcelName(item.excelFileName);
    };

    const loadTemplateGroups = async () => {
        dispatch(appActions.showSpinner());
        try {
            const newTemplateGroups = await getTemplateGroups(LanguageCode.Fi, "");
            if (!newTemplateGroups) return;
            const templateGroupOptions = newTemplateGroups.items.map(i => {
                return { id: i.code, value: i.name };
            });
            templateGroupOptions.unshift({ id: "", value: Translations.All });
            setTgOptions(templateGroupOptions);
        } catch (error) {
            console.log("loadTemplateGroups", error);
            dispatch(appActions.showError(error.toString()));
        } finally {
            dispatch(appActions.hideSpinner());
        }
    };

    useEffect(() => {
        loadUseCase();
    }, []);

    const handleImageChange = async (filePar: File) => {
        if (!filePar) return;
        const base64 = await Base.blobToBase64(filePar);
        if (!base64) return;
        setImage(base64);
    };

    const handleFileDownload = async () => {
        if (!excelName) return;
        if (excelFile) {
            saveAs(excelFile, excelName);
            return;
        }
        const newExcelFile = await callService(() => getUseCaseExcelFile(id), false);
        if (!newExcelFile) return;
        saveAs(newExcelFile, excelName);
    };

    const handleFileUpload = async (filePar: File) => {
        if (!filePar) return;
        const base64 = await Base.blobToBase64(filePar);
        if (!base64) return;
        setExcel(base64);
        setExcelFile(filePar);
        setExcelName(filePar.name);
    };

    const handleExcelUpload = async () => {
        ExcelHelper.getDocumentAsBase64((bytes: Uint8Array, error: string) => {
            if (!bytes?.length) {
                if (error) {
                    showErrorMessage(Translations.ReadingExcelFailed + " " + Translations.Error + ": '" + error + "'");
                }
                return;
            }
            const base64 = Base64.fromUint8Array(bytes);
            if (!base64) return;
            const file = Base.blobToFile(Base.base64ToBlob(base64, xlsxContentType), excelName);
            setExcel(base64);
            setExcelFile(file);
            showSuccessMessage(Translations.ExcelFileUpdated);
        });
    };

    const closeView = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    };

    const handleClose = () => {
        handleFormDialogClose(isDirty, () => handleSubmit(onSubmit)(), closeView);
    };

    //If opened from use case open view, set settings also to excel
    const setFormDataToExcel = (data: IFormData) => {
        if (!returnUrl) return;
        const excelSettings = new ExcelFileSettings();
        excelSettings.useCaseId = useCaseEdit.item.id;
        excelSettings.autoOpenTaskPane = data.autoOpenTaskPane;
        ExcelFileSettings.saveSettings(excelSettings);
    };

    const onSubmit = async (data: IFormData) => {
        const saveData = {
            ...data,
            id: useCaseEdit.item.id,
            rowId: useCaseEdit.item.rowId,
            image: Base.removeDataUrlHeader(image),
            useCaseDataId: data.useCaseDataId === Base.emptyGuid ? null : data.useCaseDataId,
            excel: excel ? Base.removeDataUrlHeader(excel) : null,
            excelFileName: excel ? excelName : null,
        };
        const result = await callServiceOnSave(() => saveUseCase(saveData));
        if (!result) return;
        setFormDataToExcel(data);
        closeView();
    };

    const handleRemove = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.UseCaseRemoveConfirmation, useCaseEdit.item.name), Translations.RemoveUseCase);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeUseCase(id));
        if (!dbResult) return;
        closeView();
    };

    if (!useCaseEdit) return null;

    return (
        <div>
            <TitleBar
                title={id !== Base.emptyGuid ? Translations.EditUseCase : Translations.NewUseCase}
            >
                <Stack className="buttons">
                    <SaveIconButton onClick={handleSubmit(onSubmit)} />
                    <RemoveIconButton onClick={handleRemove} />
                    <CloseIconButton onClick={handleClose} />
                </Stack>
            </TitleBar>
            <UseCaseEditContent>
                <FormLine>
                    <Stack>
                        <FormImageField
                            src={image}
                            height={60}
                            width={60}
                            fit="cover"
                            onFileChange={handleImageChange}
                        />
                        <UseCaseEditName>
                            <FormInput
                                label={Translations.Name}
                                name="name"
                                control={control}
                                required
                                maxLength={50}
                                autoFocus
                                rules={{ required: Translations.NameIsRequired }}
                            />
                        </UseCaseEditName>
                    </Stack>
                </FormLine>
                <FormLine>
                    <FormFileField
                        accept={".xlsx"}
                        fileName={excelName}
                        label={Translations.ExcelFile}
                        onFileDownload={handleFileDownload}
                        onFileUpload={handleFileUpload}
                        onExcelUpload={handleExcelUpload}
                    />
                </FormLine>
                <FormLine>
                    <FormTextArea
                        label={Translations.Description}
                        name="description"
                        control={control}
                        maxLength={1000}
                    />
                </FormLine>
                <FormLine>
                    <FormDropdown
                        label={Translations.Data}
                        name="useCaseDataId"
                        control={control}
                        options={useCaseDatas}
                    />
                </FormLine>
                <FormLine>
                    <FormDropdown
                        label={Translations.TemplateGroup}
                        name="templateGroup"
                        control={control}
                        options={tgOptions}
                    />
                </FormLine>
                <FormLine>
                    <FormTextArea
                        label={Translations.ToolTip}
                        name="tip"
                        control={control}
                        maxLength={1000}
                    />
                </FormLine>
                <FormLine>
                    <FormIntField
                        label={Translations.SortOrder}
                        name="sortOrder"
                        control={control}
                        min={0}
                        max={10000}
                        step={1}
                    />
                </FormLine>
                <FormLine>
                    <FormTextArea
                        label={Translations.AiddocsCallContext}
                        name="context"
                        control={control}
                        maxLength={1000}
                    />
                </FormLine>
                <FormLine>
                    <FormInput
                        label={Translations.RootElementName}
                        name="rootElementName"
                        control={control}
                        maxLength={50}
                        rules={{
                            validate: (value) => {
                                if (!Base.isValidXmlElementName(value)) {
                                    return Translations.RootElementNameIsInvalid;
                                }
                                return null;
                            }
                        }}
                    />
                </FormLine>
                <FormLine>
                    <FormInput
                        label={Translations.OmitElementsWithPrefix}
                        name="omitElementsWithPrefix"
                        control={control}
                        maxLength={50}
                    />
                </FormLine>
                <FormLine>
                    <FormBoolenField
                        label={Translations.UseCollectionElements}
                        name="useCollectionElements"
                        control={control}
                    />
                </FormLine>
                <FormLine>
                    <FormBoolenField
                        label={Translations.AllowMultiple}
                        name="allowMultiple"
                        control={control}
                    />
                </FormLine>
                <FormLine>
                    <FormBoolenField
                        label={Translations.AutoOpenAddinWhenDocumentIsOpened}
                        name="autoOpenTaskPane"
                        control={control}
                    />
                </FormLine>
            </UseCaseEditContent>

        </div>
    );
};

export default UseCaseEdit;