import ActionInsert from "./../../../../assets/ActionInsert.png";
import ActionInsertCount from "./../../../../assets/ActionInsertCount.png";
import ConditionDoesNotExist from "./../../../../assets/ConditionDoesNotExist.png";
import ConditionExists from "./../../../../assets/ConditionExists.png";
import TemplateType2 from "./../../../../assets/templateType2.svg";
import TemplateType3 from "./../../../../assets/templateType3.svg";
import TemplateType4 from "./../../../../assets/templateType4.svg";
import TemplateType5 from "./../../../../assets/templateType5.svg";
import TemplateType6 from "./../../../../assets/templateType6.svg";
import TemplateType7 from "./../../../../assets/templateType7.svg";
import { Image, ImageFit } from "@fluentui/react";
import * as React from "react";
import styled from "@emotion/styled";
import { ITemplateEditItem } from "../../models/template/templateEditItem";
import { Base } from "../../framework/base";
import { Translations } from "../../models/common/translations";
import { TemplateTypeClass } from "../../models/common/enums";

//Styles
const LineContainer = styled.div<ITemplateEditTemplateLineProps>`
    padding-left: ${(props) => props.item.level > 0 && (props.item.level * 5).toString(10) + "px"};
    display: flex;
`;

const ImageContainer = styled(Image)`
    margin-right: 5px;
`;

const TextContainer = styled.div`
    margin-right: 5px;
`;
//Component
export interface ITemplateEditTemplateLineProps {
    item: ITemplateEditItem;
}

export const TemplateEditTemplateLine = (props: ITemplateEditTemplateLineProps) => {
    return (
        <LineContainer item={props.item}>
            {props.item.level > 0 &&
                <ImageContainer
                    src={props.item.templateTypeClass === TemplateTypeClass.FragmentPageWithLayout
                        ? TemplateType3
                        : props.item.templateTypeClass === TemplateTypeClass.FragmentTableRow
                            ? TemplateType4
                            : props.item.templateTypeClass === TemplateTypeClass.FragmentInlineText
                                ? TemplateType5
                                : props.item.templateTypeClass === TemplateTypeClass.FragmentPageWithLayoutPlaceholder
                                    ? TemplateType6
                                    : props.item.templateTypeClass === TemplateTypeClass.BaseOwnerPartReference
                                        ? TemplateType7
                                        : TemplateType2}
                    height={20}
                    width={20}
                    imageFit={ImageFit.cover}
                    title={props.item.relationTag}
                />
            }
            <TextContainer>{props.item.name}</TextContainer>
            {props.item.relationId !== Base.emptyGuid &&
                <React.Fragment>
                    <ImageContainer
                        src={props.item.relationCondition
                            ? ConditionExists
                            : ConditionDoesNotExist }
                        height={20}
                        width={20}
                        imageFit={ImageFit.cover}
                        title={props.item.relationCondition || Translations.ConditionDoesNotExist}
                    />
                    <ImageContainer
                        src={props.item.relationAction.toLocaleLowerCase() === "insert"
                            ? ActionInsert
                            : ActionInsertCount }
                        height={20}
                        width={20}
                        imageFit={ImageFit.cover}
                        title={props.item.relationAction}
                    />
                </React.Fragment>
            }
        </LineContainer>
    );
};

export default TemplateEditTemplateLine;
