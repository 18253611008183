import { getFileTypeIconAsUrl } from "@fluentui/react-file-type-icons";
import * as React from "react";

export const AppExcelIcon = () => {
    const xlsxIconUrl = getFileTypeIconAsUrl({ extension: "xlsx", size: 16 });
    return (
        <img src={xlsxIconUrl} />
    );
};

export default AppExcelIcon;