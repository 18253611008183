import styled from "@emotion/styled";
import { ButtonProps, Button, Spinner } from "@fluentui/react-components";
import * as React from "react";

const StyledSpinner = styled(Spinner)`
    margin-right: 5px;
`;

export interface ISpinnerButtonProps {
    isSubmitting?: boolean;
    text?: string;
}

export const SpinnerButton = (props: ISpinnerButtonProps & ButtonProps) => {
    return (
        <Button appearance="primary" {...props} disabled={props.isSubmitting}>
            {props.isSubmitting &&
                <StyledSpinner size="tiny" />
            }
            {props.text}
        </Button>
    );
};

export default SpinnerButton;