import { UseCaseDataSetEdit } from "../useCaseDataSet/useCaseDataSetEdit";
import { UseCaseDataSetListItems } from "../useCaseDataSet/useCaseDataSetListItems";
import { UseCaseDataSetTableDatas } from "../useCaseDataSet/useCaseDataSetTableDatas";
import { getApiCall, postApiCall } from "./tokenService";

export const getUseCaseDataSetListItems = (useCaseDataId: string): Promise<UseCaseDataSetListItems> => {
    return getApiCall("usecasedataset/list?id=" + useCaseDataId, UseCaseDataSetListItems);
};

export const getUseCaseDataSetEdit = (useCaseDataId: string, useCaseDataSetId: string): Promise<UseCaseDataSetEdit> => {
    return postApiCall("usecasedataset/edit", UseCaseDataSetEdit, JSON.stringify({ id: useCaseDataId, setId: useCaseDataSetId }));
};

export const getUseCaseDataSetData = (useCaseDataId: string, useCaseDataSetId: string, jsonFormData: string): Promise<UseCaseDataSetTableDatas> => {
    return postApiCall("usecasedataset/data", UseCaseDataSetTableDatas, JSON.stringify({ id: useCaseDataId, setId: useCaseDataSetId, data: jsonFormData }));
};
