import * as React from "react";
import { Translations } from "../../models/common/translations";
import styled from "@emotion/styled";
import FileIconButton from "./FileIconButton";
import { DownloadIconButton } from "./button/DownloadIconButton";
import { UploadExcelIconButton } from "./button/UploadExcelIconButton";
import AppLabel from "./AppLabel";

//Style
const FileNameContainer = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-right: 10px;
    overflow: hidden;
`;

const Stack = styled.div`
    display: flex;
    align-items: center;
`;

//Component
export interface IFormFileFieldProps {
  accept?: string;
  label?: string;
  fileName?: string;
  onFileDownload: () => void;
  onFileUpload: (filePar: File) => void;
  onExcelUpload?: () => void;
}

export const FormFileField = (props: IFormFileFieldProps) => { // & IImageProps
    return (
        <React.Fragment>
            {props.label &&
              <AppLabel>{props.label}</AppLabel>
            }
            <Stack>
                <FileNameContainer title={props.fileName}>{props.fileName}</FileNameContainer>
                {!!props.fileName && !!props.onFileDownload &&
                  <DownloadIconButton onClick={props.onFileDownload} />
                }
                {!!props.onFileUpload &&
                  <FileIconButton title={Translations.Upload} onFileChange={props.onFileUpload} accept={props.accept} />
                }
                {!!props.onExcelUpload &&
                  <UploadExcelIconButton onClick={props.onExcelUpload} />
                }
            </Stack>
        </React.Fragment>
    );
};
