import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Translations } from "../../models/common/translations";
import { callServiceOnRemove, hasPermission, navigateWithSetReturnUrl, showConfirmationDialog, templateCanBeCopied, templateCanBeRemoved, templateStateCanBeChanged } from "../../models/common/appUtils";
import { ITemplate } from "../../models/template/template";
import { RootState, useAppDispatch } from "../../store";
import { templateActions } from "../../store/template";
import { useSelector } from "react-redux";
import { AuthorizationLevel, ConfirmationDialogResult } from "../../models/common/enums";
import { Base } from "../../framework/base";
import { removeTemplate } from "../../models/services/templateService";
import AppLabel from "../common/AppLabel";
import { RemoveIconButton } from "../common/button/RemoveIconButton";
import { OpenIconButton } from "../common/button/OpenIconButton";
import { EditIconButton } from "../common/button/EditIconButton";
import { CopyIconButton } from "../common/button/CopyIconButton";
import DetailCard from "../common/DetailCard";

//Component
export interface ITemplateDetailCardProps {
    returnUrl: string;
    template: ITemplate;
}

export const TemplateDetailCard = (props: ITemplateDetailCardProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const templateListId = useSelector((state: RootState) => state.template.templateListId);
    const [footerAction, setFooterAction] = React.useState<React.ReactElement>(null);

    const handleCopyTemplate = () => {
        dispatch(templateActions.setTemplateCopy({ template: props.template, returnUrl: props.returnUrl }));
        navigate("/templatecopy");
    };

    const handleEditTemplate = () => {
        navigateWithSetReturnUrl(navigate, "/templateedit/" + props.template.code, props.returnUrl);
    };

    const removeTemplateFromDb = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.TemplateRemoveConfirmation, props.template.name), Translations.RemoveTemplate);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeTemplate(props.template.id));
        if (!dbResult) return;
        dispatch(templateActions.setTemplate(null));
        dispatch(templateActions.setTemplateListId(templateListId + 1));
    };

    const handleRemoveTemplate = () => {
        removeTemplateFromDb();
    };

    React.useEffect(() => {
        if (props.template && hasPermission(authUser, AuthorizationLevel.Reader)) {
            setFooterAction(<div>
                {templateCanBeCopied(authUser, props.template) &&
                    <CopyIconButton key="copy" onClick={handleCopyTemplate} />
                }
                {(templateStateCanBeChanged(authUser, props.template)
                    ? <EditIconButton key="edit" onClick={handleEditTemplate} />
                    : <OpenIconButton key="edit" onClick={handleEditTemplate} />)
                }
                {templateCanBeRemoved(authUser, props.template) &&
                    <RemoveIconButton key="remove" onClick={handleRemoveTemplate} />
                }
            </div>);
        } else {
            setFooterAction(null);
        }
    }, [props.template, authUser]);

    return (
        <DetailCard
            footerAction={footerAction}
            details={
                props?.template
                    ? (<div>
                        <AppLabel>{Translations.Code}</AppLabel>
                        <div>{props.template.code || "-"}</div>
                        <AppLabel>{Translations.Name}</AppLabel>
                        <div>{props.template.name || "-"}</div>
                        <AppLabel>{Translations.State}</AppLabel>
                        <div>{props.template.stateCode || "-"}</div>
                        <AppLabel>{Translations.Comment}</AppLabel>
                        <div>{props.template.comment || "-"}</div>
                        <AppLabel>{Translations.Format}</AppLabel>
                        <div>{props.template.formats?.length ? props.template.formats.join(", ") : "-"}</div>
                    </div>)
                    : null
            }
            title={props.template.name}
        />
    );
};

export default TemplateDetailCard;
